/* General Styles */
body {
    margin: 0;
    padding: 0;
    color: #333;
  }
  
  .landing-page {
    text-align: center;
  }
  
  /* Header */
  .header {
    background-color: #a574d2;
    padding: 10px;
    color: white;
    font-size: 14px;

    p {
      color: white;
    }
  }
  
  .header a {
    color: white;
    font-weight: bold;
    text-decoration: underline;
  }

  .need-help {
    color: #9260ca;
    padding: 20px 0px 20px 21%;
    font-size: medium;
    font-weight: bold;
    text-align: left;
    margin-bottom: 0;
    cursor: pointer;
  }

  .need-help-body {
    /* style={{ textAlign: "center", fontSize: 'small', padding: '3%' }} */
    text-align: center; 
    font-size: small;
    padding: 3%;

    p{
      font-size: small;
    }
  }
  
  /* Hero Section */
  .hero {
    background-color: #f4eaff;
    padding: 40px 20px 40px 21%;
    text-align: left;
  }
  
  .hero h1 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .hero p {
    font-size: 16px;
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 20px;
  }
  
  .signup-form input, .signup-form select, .signup-form button {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .signup-form button {
    background-color: #ff6677;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  /* Features Section */
  .features {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 40px;
    background-color: white;
  }
  
  .feature {
    text-align: center;
    max-width: 250px;
  }
  
  .feature span {
    font-size: 40px;
    display: block;
  }
  
  .feature h3 {
    font-size: 18px;
    margin-top: 10px;
  }
  
  .feature p {
    font-size: 14px;
    color: #666;
  }
  
  /* Testimonials */
  .testimonials {
    background-color: #f4eaff;
    padding: 40px;
  }
  
  .testimonials h2 {
    font-size: 22px;
  }
  
  .testimonials blockquote {
    font-style: italic;
    margin-top: 10px;
  }
  
  /* Requirements */
  .requirements {
    background-color: #f4eaff;
    padding: 0px 40px 6px 40px;
  }
  
  .requirements h2 {
    font-size: 22px;
  }
  
  .requirements ul {
    list-style: none;
    padding: 0;
  }
  
  .requirements li {
    margin: 10px 0;
  }
  
  /* Footer */
  .footer {
    background-color: #512e5f;
    color: white;
    padding: 20px;
    font-size: 14px;
  }
  
  .reviewTag {
   width: 30%;
    height: 120px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: center;
    background-position-y: center;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 35%;
  }