body,
h1,
h2,
h3,
h4,
h5,
p {
  font-family: "Hanken Grotesk", serif;
  font-weight: 400;
  font-style: normal;
}
body {
  margin: 0;
  font-family: "Hanken Grotesk", serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: #3d1f6c;
}

ul li {
  margin-top: 0;
  font-weight: 300;
  color: #51565e;
  font-size: 16px;
  line-height: 26px;
}

/*=======HEADER========*/

/*==================Scrolbar*/

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 8px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.flexBeet {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loginbtn {
  border: 1px solid #cfcfcf;
  border-radius: 40px;
  padding: 7px 14px;
  line-height: 1;
  color: #4d4d4d;
  max-height: 34px;
  font-size: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.loginbtn img {
  margin-left: 10px;
}

.navItems {
  p {
    color: black;
    font-weight: 400;
  }
}

.navbar-brand img {
  width: 139px;
}

.nav-logo {
  padding: 1rem 1rem 1rem 0;
}

.nav-left {
  display: flex;
  flex: 1 1 auto;
  margin-right: auto;
}

@media (max-width: 1024px) {
  .nav-left {
    display: none;
  }

  .nav-right {
    display: none;
  }
}

.nav-left a {
  margin-left: 1rem;
  padding: 0.8125em 0;
  color: #434343 !important;
}
/* .hanken-grotesk-abc123 {
  font-family: "Hanken Grotesk", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
}

.hanken-grotesk-def456 {
  font-family: "Hanken Grotesk", serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
}

.hanken-grotesk-ghi789 {
  font-family: "Hanken Grotesk", serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
} */

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .nav-left {
    flex-wrap: wrap;
    /* Wrap items to the next line on medium screens */
    justify-content: center;
    /* Center-align items */
  }

  .nav-left a {
    margin-left: 0.5rem;
    /* Reduce margin between links */
    padding: 0.5em 0;
    /* Adjust padding */
  }

  .navbar-brand img {
    width: 120px;
    /* Scale down the logo */
  }
}

@media (max-width: 768px) {
  .nav-left {
    flex-direction: column;
    /* Stack navigation items vertically */
    align-items: center;
    margin-right: 0;
    /* Remove margin-right for better centering */
  }

  .nav-left a {
    margin: 0.5rem 0;
    /* Add vertical spacing between items */
  }

  .nav-logo {
    padding: 0.5rem 0;

    /* Adjust padding */
  }

  .navbar-brand img {
    width: 100px;
    /* Further scale down the logo */
  }
}

@media (max-width: 768px) {
  .mobile-header {
    background: transparent url("/public/images/logo.webp") no-repeat;
    height: 35px;
    mix-blend-mode: normal;
    opacity: 1;
    width: 154px;
    cursor: pointer;
    position: relative;
    background-size: contain;
    margin-right: 108px;
  }
}

@media (max-width: 480px) {
  .nav-left {
    flex-direction: column;
    /* Keep items stacked vertically */
    align-items: center;
  }

  .nav-left a {
    font-size: 0.875rem;
    /* Reduce font size for smaller screens */
  }

  .navbar-brand img {
    width: 80px;
    /* Adjust logo size for very small screens */
  }

  .nav-logo {
    padding: 0.25rem 0;
    /* Reduce padding */
  }
}

.homeServices {
  .container {
    h1 {
      font-size: 2em;
      padding: 0;
      font-weight: 900;
    }

    .homeservice-para2 {
      h4 {
        font-size: 2rem;
        font-weight: 500;
        margin-top: 1.07em;
      }
    }

    .servicesSection {
      padding-right: 1rem;
      max-width: 75rem;
      display: flex;
      flex-wrap: wrap;
      /* Allow wrapping for smaller screens */
      margin-top: 1.45rem;

      .service-Link {
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
        color: black;
        margin: 0 auto;
      }

      .serviceIcon {
        width: 72px;
        height: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .d-flex {
        border-top: 1px solid #eaeaea;
        align-items: center;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        margin: 1rem;
        margin-left: 0rem;
        /* Add margin for better spacing */
      }
    }

    @media (min-width: 721px) {
      .d-flex:first-child {
        margin-right: 2.75rem;
      }

      .d-flex {
        width: calc(25% - 2rem);
        /* 4 columns by default */
      }
    }
  }

  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    .servicesSection {
      .d-flex {
        width: calc(33.33% - 1rem);
        /* 3 columns for medium screens */
      }
    }
  }

  @media (max-width: 720px) {
    .servicesSection {
      flex-direction: column;
      /* Stack elements vertically */
      align-items: center;
      width: 100%;

      .d-flex {
        width: 100%;
        /* Full width for smaller screens */
      }
    }

    h1 {
      font-size: 2em;
      /* Smaller title font */
    }

    .homeservice-para2 h4 {
      font-size: 1.8rem;
      /* Adjust font size */
    }
  }

  @media (max-width: 480px) {
    .servicesSection {
      padding-right: 0.5rem;

      .service-Link {
        font-size: 0.9rem;
        /* Smaller text */
      }

      .serviceIcon {
        width: 60px;
        /* Smaller icons */
        height: 60px;
      }
    }

    h1 {
      font-size: 1.8em;
      /* Smaller title for very small screens */
    }
  }
}

.grid-container {
  max-width: 75rem;
  margin-bottom: 1.5rem;
}

.container {
  margin-top: 3em;
  margin-bottom: 3em;

  .grid-container {
    max-width: 75rem;
    margin-bottom: 1.5rem;

    .grid-margin-x {
      margin-left: -1rem;
      margin-right: -1rem;

      .shrink {
        width: auto;
        min-width: 105%;

        h2 {
          font-size: 2rem;
          line-height: 1.4;
          margin-top: 0;
          margin-bottom: 1rem;
        }

        .description {
          font-size: 1.1rem;
          color: black;
        }

        @media (max-width: 720px) {
          .description {
            width: 90%;
          }
        }
      }

      .popular-card {
        position: relative;
        display: flex;
        justify-content: flex-start;
        flex: 1 1 auto;

        .card-link {
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          margin-right: 2%;
        }

        @media (max-width: 768px) {
          .card-link {
            margin-bottom: 5%;
          }
        }
      }

      @media (max-width: 1200px) {
        .popular-card {
          flex-wrap: wrap;
          margin-left: 15%;
          margin-right: 15%;
          margin-bottom: 15% !important;
        }

        .card-link + .card-link {
          margin-left: 0 !important;
        }
      }

      @media (max-width: 768px) {
        .popular-card {
          flex-wrap: wrap;
          margin-left: 10%;
          margin-right: 10%;
          margin-bottom: 15% !important;
        }
      }
    }
  }
}

.cell {
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
}

.become-pro .become-pro-container {
  padding: 1.5rem 0.5rem;
  text-align: left;
}

.become-pro {
  height: 640px;

  .grid-container {
    max-width: inherit !important;

    .grid-x {
      justify-content: space-between;
    }
  }
}

.become-pro .become-pro-title {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1.25rem;
}

.become-pro .become-pro-para {
  margin-bottom: 2.5rem;
}

.color-rh {
  background-color: #9e6dc8;
}

.pro-button {
  display: inline-block;
  height: 56px;
  width: 268px;
  max-width: 100%;
  line-height: 56px;
  text-align: center;
  border-radius: 5px;
  font-size: 1.125em;
  color: #ffffff;
  border-radius: 30px;
}

@media (min-width: 1024px) {
  .grid-x {
    display: flex;
  }
}

.align-middle {
  align-items: center;
}

.service-image {
  width: 280px;
  height: 157px;
  object-fit: inherit;
}

.service-name {
  text-align: center;
  color: black;
  font-size: 1.25rem;
}

.cell.shrink {
  flex: 0 0 auto;
}

.dropdown-menu li {
  cursor: pointer;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.amount p span {
  font-weight: 300;
  color: #707070;
  padding-left: 10px;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}

.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.accordion-item .accordion-button:focus {
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%) !important;
}

.accordion-flush .accordion-item .accordion-button {
  background: none !important;
  color: #222;
}

.heading h5 a {
  color: #707070;
}

.accordion-body ul {
  margin-top: 10px;
  padding-left: 15px;
}

.faqtab .tabBtn {
  outline: 0;
}

#root .navbar {
  background-color: #fff !important;
}

select.form-control {
  color: #707070;
  font-weight: 300;
}

#loginModal .inputgroup:nth-child(1) {
  padding-right: 0 !important;
  width: 32% !important;
}

#loginModal .inputgroup:nth-child(2) {
  padding-left: 0 !important;
  width: 68% !important;
}

.login_country_code {
  border-radius: 8px 0px 0px 8px !important;
  border-right: 5px !important;
  background-color: #fff !important;
  padding-right: 0 !important;
}

#loginModal .login_country_code#country_code {
  padding-left: 5px;
  text-align: center;
}

.login_mobile {
  padding-left: 0;
  border-radius: 0 8px 8px 0px !important;
  border-left: 0 !important;
  padding-left: 0px !important;
}

[dir="rtl"] .login_mobile {
  margin-left: 5px !important;
  padding-right: 5px !important;
  border-radius: 8px 8px 8px 8px !important;
  border-left: 1px solid grey !important;
}

[dir="rtl"] .react-tel-input .selected-flag {
  padding-right: 15%;
}

.owl-nav .slick-prev {
  left: 0 !important;
}

.owl-nav .slick-next {
  right: 0 !important;
}

.professionalModal .professionalInfoCard {
  margin-bottom: 15px;
}

.loginMobileInput {
  /* direction: ltr; */
  margin-top: 15px;
  margin-bottom: 15px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

#loginForm .inputgroup:nth-child(1) {
  padding-right: 0 !important;
  width: 30% !important;
}

#loginForm .inputgroup:nth-child(2) {
  padding-left: 0 !important;
  width: 70% !important;
}

#loginForm .inputdiv .phonecodeselect .form-control {
  padding-left: 50px;
}

/* custom */
.SliderbgImage {
  height: 370px;
  background-size: cover;
  background-position: 100% 100%;
}

.packageSlider .checkboxItem {
  display: table;
  margin: 0 auto 34px;
}

#loginAddress .modal-body {
  padding: 0;
}

.appointmentCartModalBody {
  padding: 0px 25px 30px !important;
  margin-top: -12px !important;
}

.react-tel-input .country-list {
  position: fixed !important;
  bottom: 30%;
  z-index: 99999999;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  width: 340px !important;
  max-height: 220px !important;
}

.appointmentCartModalBody {
  padding: 20px 23px !important;
  border-bottom: 1px solid #fff !important;
}

.login_country_code#country_code {
  margin-left: 19px !important;
  border: 1px solid #cfcfcf !important;
  border-radius: 8px !important;
  text-align: left;
}

.cursor-pointer {
  cursor: pointer !important;
  line-height: 2 !important;
}

.changeEye {
  position: relative;
}

.changeEye i {
  position: absolute;
  right: 20px;
  top: 48px;
  font-size: 25px;
  color: #432375;
  cursor: pointer;
}

.selectallhistory {
  border-radius: 8px !important;
}

.publishDate.BlankLoadingBody {
  height: 550px;
}

.applyCoupon {
  padding: 20px;
  border-radius: 8px;
  background-color: #f2e9ff;
  margin-top: 5px;
}

.applyCoupon h4 {
  margin-bottom: 15px;
  line-height: 1;
  font-weight: normal;
}

.applyCoupon h3 {
  font-size: 20px;
  font-weight: bold;
  color: #432375;
  display: flex;
}

.applyCoupon h3 span {
  background-color: #432375;
  padding: 5px 12px;
  margin-left: 15px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: normal;
  color: #fff;
}

p.cancelPromoCode {
  float: right;
  margin-top: 16px;
  cursor: pointer;
}

/*div#otpVerify .btn-close {display: none !important;}*/
/*Success*/
#bookingSuccess .modal-body {
  padding: 0;
  padding-bottom: 10px;
}

#bookingSuccess .card-content ul li {
  line-height: 24px;
}

.successPage {
  background: #f8f3ff;
  padding: 0px 0;
}

.successCard {
  padding-top: 15px;
  max-width: 100%;
  margin: 0 auto;
  min-height: 300px;
  text-align: center;
  background-color: #fff;
  background-position: center -30px;
}

.successCard .checkicon {
  padding: 8px;
  background: #432375;
  color: #432375;
  text-align: center;
  border-radius: 50%;
  font-size: 25px;
  -webkit-text-stroke: 2px;
  -webkit-text-fill-color: #fff;
}

.successCard h2 {
  margin-bottom: 0px;
  font-size: 20px;
}

.successCard h3 {
  color: #432375;
  font-size: 16px;
}

.successCard .card-content {
  padding: 8px 20px;
}

.successCard ul li {
  flex-wrap: wrap;
  font-size: 14px;
}

.successCard ul {
  margin-top: 5px !important;
}

.successCard ul li strong {
  font-weight: bold;
  color: #432375;
}

.successCard p {
  margin: 20px 0;
}

.successCard .btn {
  margin: 10px 0 5px 0;
}

.mapToggle .inputdiv {
  margin-bottom: 0;
}

.cleaner-image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 12%);
  margin-top: 7px;
}

.is-flex {
  display: flex !important;
}

p.body-text-item.has-text-right {
  font-weight: normal;
}

.cleaner-image {
  margin-left: 1rem;
}

.professionalInfoCard img {
  width: 100px;
  height: 100px;
  border-radius: 47%;
  margin: 0 auto 9px;
  display: block;
}

.professionalInfoCard {
  padding: 0px 0 5px 0;
  border-bottom: 1px solid #cdcdcd;
}

/* custom */
.date_selected {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  background-color: #432375 !important;
  border: 2px solid #432375 !important;
}

.static_info {
  cursor: pointer;
}

p.mb-0.py-3.resentOtpTime.timeNotVisible {
  display: none !important;
}

p.helper-text-item.has-margin-top.has-text-alternate-dark {
  border-radius: 11px;
  background: #f2e8ff;
  font-size: 12px;
  color: #3d1c6f;
  margin-top: 5px;
  text-align: center;
}

.translated-rtl .professionalSec .item {
  transform: rotate(-180deg);
}

.translated-rtl .professionalSec .owl-carousel {
  transform: rotate(180deg);
}

.accordion-body.content_not_found {
  margin-bottom: 250px !important;
  margin-top: 250px !important;
}

h6.content_not_found_text {
  color: #707070;
}

i.fa.fa-heart.myfvtclr {
  float: right;
  color: #9e6dc8;
}

#root .creditPackage .finalPaymentButton {
  background-color: #fff !important;
  padding: 0 !important;
  border: 0 !important;
  margin: 0 !important;
}

.cleaner-image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 12%);
  margin-top: 7px;
}

.is-flex {
  display: flex !important;
}

p.body-text-item.has-text-right {
  font-weight: normal;
}

.cleaner-image {
  margin-left: 1rem;
}

.professionalInfoCard img {
  width: 100px;
  height: 100px;
  border-radius: 47%;
  margin: 0 auto 9px;
  display: block;
}

.professionalInfoCard {
  padding: 0px 0 5px 0;
  border-bottom: 1px solid #cdcdcd;
}

.professionalModal .professionalInfoCard {
  margin-bottom: 15px;
}

.activeProfessional {
  background: #f2e8ff !important;
  border: 1px solid #3d1c6f !important;
}

#profDetails .transtionList {
  padding: 10px 24px;
}

#profDetails .recentTranstion > h3 {
  padding-left: 24px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.infinite-scroll-component {
  height: 800px !important;
  overflow: scroll !important;
}

.professionalModal p i {
  margin-right: 5px;
  color: #fdc017;
  font-size: 16px;
}

.professionalModal .transtionList > h3 span {
  font-size: 14px;
}

.reviewBar {
  padding: 24px 24px 0 24px;
}

.professionalModal {
  height: 500px;
  overflow-y: auto;
}

.barArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.barArea p {
  margin-bottom: 0;
}

.reviewProgress {
  width: 70%;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.08);
  position: relative;
}

.barcolor {
  position: absolute;
  top: 0;
  width: 100%;
  height: 4px;
  background-color: #432375;
}

.w10 {
  width: 10%;
}

.w20 {
  width: 20%;
}

.w30 {
  width: 30%;
}

#profDetails .modal-body {
  padding: 0;
}

.appointmentListBox {
  height: 400px;
  overflow: auto;
}

.hairService .owl-stage-outer .item {
  padding: 15px 0;
}

.hairService .serviceCategoryBtn:hover {
  color: #51565e;
}

.hairService .tabBtn-active:hover {
  color: #fff;
}

.serviceCategoryBtn {
  min-width: 90%;
  font-weight: 500;
  padding: 8px 10px;
  font-size: 15px;
  text-align: center;
  border-radius: 30px;
  color: #51565e;
  cursor: pointer;
  border: 2px solid #d6d6d6;
  width: 95%;
  display: table;
  margin: 0 auto;
}

/* service button active */
.serviceCategoryBtn .serviceCategoryBtn-active {
  background: #432375;
  border: 2px solid #432375 !important;
  color: #fff !important;
}

input#saveCard {
  width: 20px;
  height: 20px;
}

.address_input_div {
  z-index: 11;
  position: relative;
}

#profileForm .react-tel-input .form-control {
  border-radius: 30px !important;
  cursor: pointer !important;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.allInputDiv {
  z-index: 11;
  position: relative;
}

/* .owl-carousel .owl-stage{ margin: 0 auto;width:1765px !important;} */
.languageChecked {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.languageChecked-homepage-modal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.languageChecked p {
  margin-bottom: 0;
}

.languageChecked input[type="radio"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.languageChecked i {
  display: none;
  -webkit-text-stroke: 1.5px white;
  opacity: 0.5;
}

/* .languageChecked:last-child{ margin-bottom: 0;} */
.languageChecked input[type="radio"]:checked + i {
  display: block;
  font-size: 25px;
}

.phoneNumberDir {
  direction: ltr;
}

.selectedLang {
  font-size: 16px;
  cursor: pointer;
}

.help-block {
  color: #e11d11 !important;
}

.pac-container {
  z-index: 9999 !important;
}

.roundedCircle img {
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

#otpVerify p.btn.btn-light {
  max-width: 146px;
  margin: 0 auto 15px;
  font-size: 16px;
  background: #e7e7e7 !important;
  color: #222222 !important;
}

#otpVerify {
  max-width: 366px;
}

#otpVerify .modal-body {
  text-align: center;
}

#otpVerify .modal-body input {
  height: 4rem;
  text-align: center;
  font-weight: 700;
  font-size: 30px;
}

/* #otpVerify .modal-body .btn{     pointer-events: none; } */
button.btn.btn-primary.w-full.disabled {
  background-color: #9e6dc8;
  border-color: #9e6dc8;
  cursor: no-drop !important;
  pointer-events: auto;
}

.searchPlace i.fa.fa-search {
  left: 25px;
  opacity: 0.6;
  position: absolute;
  top: 18px;
}

.searchPlace {
  position: absolute;
  top: 20px;
  max-width: 100%;
  left: 0;
  right: 0;
}

select.form-control {
  color: #707070;
  font-weight: 300;
}

.searchPlace input.pac-target-input {
  height: 50px !important;
  outline: 0;
}

input.pac-target-input {
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 0.25rem;
  color: #222;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 auto;
  padding: 0.66rem 0.75rem;
  /* padding-left: 50px !important; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100% !important;
  max-width: 460px;
}

.autosign {
  position: "absolute";
  top: "5px";
  right: "5px";
}

.googleMap {
  height: 500px;
  width: 100%;
}

p.mb-0.py-3.resentOtpTime {
  display: flex !important;
  justify-content: center;
}

p.btn.btn-light.resend-otp {
  background: #ddd;
  width: auto;
  padding: 4px 16px;
  border-radius: 20px;
  color: #9fa2a7;
  margin: 6px 99px;
}

.mapToogleDiv {
  background: #fff;
  padding: 20px 10px 30px 10px;
}

.mapContent {
  padding: 20px 0 30px;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.mapContent .inputdiv i {
  position: absolute;
  left: 15px;
  top: 18px;
  opacity: 0.3;
}

.mapContent .inputdiv .form-control {
  padding-left: 50px;
  border-radius: 8px;
}

/*MapToggle*/
.mapToggle {
  width: 100% !important;
  background: #fff;
  position: inherit !important;
  top: 0 !important;
  padding: 25px 0px 20px;
  border: 0 !important;
  border-radius: 0;
  box-shadow: none !important;
}

.mapToggle input {
  padding: 0.66rem 1.2rem !important;
  min-height: 45px !important;
}

.mapToggle button {
  border: 0;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  background: none;
  font-size: 35px;
  padding: 0;
  opacity: 0.3;
}

/* custom */
.modal-body .accordion-body {
  padding: 1rem 0rem;
}

.page_loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: #f2e9ffed;
  z-index: 555;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.page_loader .spinner-border {
  position: static;
  text-align: center;
}

.page_loader p {
  margin-bottom: 0;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 500;
}

.page_loader .spinner-border {
  width: 2.2rem;
  height: 2.2rem;
}

#root .roundedCircle img {
  border-radius: 100%;
  width: 30px !important;
  max-width: 30px !important;
  max-height: 20px;
  width: 30px !important;
  border: 1px solid #dddddd;
  height: 30px !important;
  min-width: 30px !important;
  min-height: 30px;
  object-fit: cover;
}

.language img {
  border-radius: 0%;
  border: 0px solid #cfcfcf;
  width: auto;
}

.navbar {
  z-index: 555;
  background-color: #fff !important;
  transition: transform 0.3s ease-out;
  transform: translate(0, 0px);
  height: 7rem;
}

.stickyhead {
  left: 0 !important;
  position: fixed;
  top: 0;
  width: 100%;
  transform: translate(0, 0px);
  z-index: 55;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.101);
}

.navbar .dropdown-menu {
  height: auto;
  overflow-y: auto;
  z-index: 9999;
  box-shadow: 0 0px 6px -5px rgb(67 35 117 / 10%),
    0 0px 10px 5px rgb(67 35 117 / 10%), 0 0px 10px 2px rgb(67 35 117 / 10%);
  border: 0;
  right: 0;
  min-width: 324px;
  padding: 0;
  left: auto;
}

.navbar .dropdown-toggle::after {
  display: none;
}

.navbar .dropdown-item {
  border-bottom: 1px solid #cdcdcd;
  font-weight: 500;
  padding: 1rem 1.5rem;
  color: #1f1f1f;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar .dropdown-menu li:last-child .dropdown-item {
  border-bottom: 0;
}

.navbar .dropdown-item:focus,
.dropdown-item:hover {
  color: #1f1f1f;
  background-color: none;
}

.navbar .dropdown-item.active,
.dropdown-item:active {
  color: #1f1f1f !important;
  text-decoration: none;
  background-color: inherit !important;
}

.navbar .dropdown-item img {
  width: 26px;
  opacity: 0.3;
}

#loginModal {
  max-width: 366px;
  width: 100%;
}

#cancelAppoitment .modal-dialog {
  max-width: 366px;
}

#addCard {
  max-width: 366px;
}

#addVocher .modal-dialog {
  max-width: 366px;
}

#selectAddress .modal-dialog {
  max-width: 366px;
}

#verifyModal .modal-dialog {
  max-width: 366px;
}

#verifyModal .modal-body input {
  height: 4rem;
  text-align: center;
  font-weight: 700;
  font-size: 30px;
}

#verifyModal .modal-body .btn {
  pointer-events: none;
  opacity: 0.1;
}

.loader {
  position: relative;
}

#addVocher .form-control {
  height: 4rem;
  text-align: center;
  font-weight: 700;
  font-size: 30px;
}

.spinner-border {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 2rem;
  height: 2rem;
  border-width: 2px;
  border: 0.25em solid #941bd6 !important;
  border-right-color: transparent !important;
}

.toast {
  background-color: #fff;
  border: 0 !important;
  top: 30%;
  margin: 0 auto;
  width: 480px !important;
  z-index: 556666;
  position: fixed;
  left: 0;
  right: 0;
}

.toast-header {
  position: relative;
  padding: 0 !important;
}

.toast-header button {
  justify-content: end;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 8px;
}

.toast-body p {
  margin-bottom: 0;
  font-size: 14px;
}

.toast-body .flex {
  display: flex;
}

.checkgrren {
  margin-right: 15px;
}

.checkgrren span {
  border-radius: 50%;
  background-color: #49a361;
  color: #fff;
  padding: 6px;
}

.error.toast {
  border-bottom: 4px solid #e41b25;
}

.success.toast {
  border-bottom: 4px solid #349931;
  border-bottom: 4px solid #349931 !important;
}

.success .fa.fa-check {
  background-color: #349931;
}

.error .fa.fa-times-circle {
  background-color: #e41b25;
}

/* #verifyModal .modal-dialog input:focus + .btn{     pointer-events: none; opacity: 1;} */

.flagdiv {
  position: absolute;
  z-index: 1;
  left: 10px;
}

.flagdiv img {
  border-radius: 50%;
  margin-right: 10px;
}

#loginModal .inputgroup {
  margin-bottom: 40px;
}

.inputgroup {
  position: relative;
  display: flex;
  align-items: center;
}

.inputgroup select {
  border: 0;
}

.inputgroup .form-control {
  padding-left: 130px;
  height: 47px;
}

.modal-dialog .btn {
  width: 100%;
}

.addAddressModal .modal-body .modal-body {
  padding: 0;
}

/*=======BANNER========*/
.bannerSection {
  background: url(https://dvdljkkxpxqo3.cloudfront.net/images/banner-image.webp);
  background-position: center;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 483px;
}

.bannerSection .bannerContent {
  max-width: 480px;
}

.bannerSection .bannerContent h1 {
  line-height: 1.25;
  margin-bottom: 39px;
}

.bannerSection .bannerContent .btn-primary {
  border-radius: 30px !important;
  padding: 13px 30px !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  min-width: 230px;
}

.form-check-input:checked[type="radio"] {
  background: url(https://dvdljkkxpxqo3.cloudfront.net/images/checked.png.webp);
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  border: 0;
}

/*=======Buttonprimary========*/

.py-7 {
  padding: 70px 0;
}

.py-5 {
  padding: 50px 0;
}

.pt-7 {
  padding-top: 70px;
}

/*=======ServiceBanner========*/

section {
  padding: 70px 0;
}

.heading {
  text-align: center;
  margin-bottom: 32px;
}

.heading h2 {
  color: #1f1f1f;
  margin-bottom: 0;
}

.heading h5 {
  font-weight: 300;
  line-height: 1;
  font-size: 17px;
  color: #707070;
  padding-bottom: 15px;
  line-height: 1;
  text-transform: uppercase;
}

.heading p {
  padding-top: 16px;
}

/* .owl-carousel .owl-stage {display: flex;}
.owl-carousel   .owl-item {display: flex;flex: 1 0 auto;}
.serviceBanner .item{ display: flex;} */
.serviceSlider {
  /* background: #f2e9ff; */
  cursor: pointer;
  padding: 10px;
  min-height: 124px;
  border-radius: 8px;
  text-align: center;
}

.serviceSlider span {
  display: flex;
  margin: 0 auto 10px;
  /* align-items: center;
  justify-content: center;border-radius: 50%; */
  /* background-color: #fff;
  border:1px solid #ebdeff; */
  width: 100%;
  /* height: 65px; */
}

/* .serviceSlider span img{ width: 40px !important;}  */
.serviceSlider span img {
  width: 100% !important;
  /* height: 100%;    padding: 2px;
border-radius: 50%; */
  object-fit: contain;
  max-height: 120px;
  border-radius: 8px;
  min-height: 80px;
}

.serviceSlider h3 {
  margin-bottom: 2px;
  font-weight: 500;
  color: #1f1f1f;
}

.serviceSlider p {
  font-size: 13px;
  line-height: 20px;
}

.serviceBanner .serviceSlider .btn {
  max-width: 130px;
  left: 0;
  right: 0;
  margin: 0 auto 12px;
  padding: 4px 30px !important;
  font-size: 14px !important;
  font-weight: normal !important;
}

.serviceSlider:hover {
  background-color: #432375;
  color: #fff;
}

.serviceSlider:hover h3 {
  color: #fff;
}

.serviceSlider:hover p {
  color: #fff;
}

.serviceSlider:hover .btn {
  background-color: #ddbb03;
  border-color: #ddbb03;
}

.owl-nav i {
  font-size: 35px;
  font-weight: bold;
}

.extraCard {
  border-radius: 8px;
  color: #fff;
}

/* .extraCard img { margin-top: 22px;} */
.extraCard .extraContent {
  padding-left: 20px;
  margin-top: 27px;
  padding-right: 35px;
}

.extraCard .extraContent h3 {
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

.extraCard .extraContent p {
  color: #fff;
}

.extraCard img {
  margin-top: 4px;
}

.extraCard .btn {
  position: absolute;
  bottom: 24px;
  right: 24px;
}

.blogDiv {
  border: 1px solid #e7e7e7;
  border-radius: 8px;
}

.blogSecImage {
  min-height: 204px;
  max-height: 204px;
  width: 100%;
  position: relative;
}

.blogDiv img {
  border-radius: 8px 8px 0 0;
  width: 100%;
  min-height: 207px;
  max-height: 207px;
}

.blogContent {
  margin-top: -50px;
  padding: 25px;
  position: relative;
  z-index: 12;
}

.blogContent .btn {
  padding: 8px 30px;
}

.blogContent h3 {
  margin-top: 25px;
}

.blogContent span {
  margin: 15px 0;
  display: block;
  color: #707070;
  font-size: 14px;
  font-weight: 300;
}

.blogContent p {
  margin-bottom: 0;
}

.lifeEasySec .heading {
  margin-bottom: 110px;
}

.lifeEasy h3 {
  margin: 40px 0 15px 0;
}

.manyReasonsec {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 470px;
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
}

.manyReasonsec:after {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  top: 0;
}

.manyReasonsec .container {
  position: relative;
  z-index: 1;
  color: #fff;
  max-width: 950px;
}

.manyReasonsec .container h2 {
  color: #8c8e90;
}

.manyReasonsec .container .heading {
  margin-bottom: 60px;
}

.manyReasonsec .checkicon i {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #037cc9;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.manyReasonsec .checkicon-white i img {
  filter: none !important;
}

.reasonContent p {
  color: #8c8e90;
  margin-bottom: 10%;
  padding-right: 10px;
}

.reasonContent h3 {
  margin-bottom: 10px;
  margin-top: 5px;
  color: #8c8e90;
}

.spinner-border {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 2rem;
  height: 2rem;
  border-width: 2px;
  border: 0.25em solid #9e6dc8 !important;
  border-right-color: transparent !important;
}

.toast {
  background-color: #fff;
  border: 0 !important;
  top: 30%;
  margin: 0 auto;
  width: 480px !important;
  z-index: 556666;
  position: fixed;
  left: 0;
  right: 0;
}

.toast-header {
  position: relative;
  padding: 0 !important;
}

.toast-header button {
  justify-content: end;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 8px;
}

.toast-body p {
  margin-bottom: 0;
  font-size: 14px;
}

.toast-body .flex {
  display: flex;
}

.checkgrren {
  margin-right: 15px;
}

.checkgrren span {
  border-radius: 50%;
  background-color: #49a361;
  color: #fff;
  padding: 6px;
}

.error.toast {
  border-bottom: 4px solid #e41b25;
}

.success.toast {
  border-bottom: 4px solid #349931;
  border-bottom: 4px solid #349931 !important;
}

.success .fa.fa-check {
  background-color: #349931;
}

.error .fa.fa-times-circle {
  background-color: #e41b25;
}

/* #verifyModal .modal-dialog input:focus + .btn{     pointer-events: none; opacity: 1;} */

.flagdiv {
  position: absolute;
  z-index: 1;
  left: 10px;
}

.flagdiv img {
  border-radius: 50%;
  margin-right: 10px;
}

#loginModal .inputgroup {
  margin-bottom: 40px;
}

.inputgroup {
  position: relative;
  display: flex;
  align-items: center;
}

.inputgroup select {
  border: 0;
}

.inputgroup .form-control {
  padding-left: 16px;
  height: 47px;
}

.modal-dialog .btn {
  width: 100%;
}

.testimonialDiv {
  border: 1px solid #e7e6e6;
  border-radius: 8px;
}

.testimonialContent {
  padding: 30px 25px 25px 25px;
  width: 80%;
}

.testimonialContent img {
  width: auto !important;
  margin-bottom: 20px;
}

.testimonialSec .heading {
  margin-bottom: 56px;
}

.testimonialContent h3 {
  margin-top: 20px;
}

.testimonilaImage img {
  width: 226px !important;
  border-radius: 8px 0 0 8px;
}

.testimonialContent span {
  font-size: 14px;
}

.fiveStar i {
  margin: 0 2px;
  color: #ffffff;
  font-size: 18px;
  background: #01b57a;
  padding: 5px;
}

.loadmore:hover {
  background: none !important;
  color: #ddbb03 !important;
}

p.loadmore.text-center {
  font-weight: 400;
  cursor: pointer;
  color: #1f1f1f;
}

.recentTranstion.allhistory {
  overflow-y: scroll;
  height: 999px;
}

.rightHandsApp {
  padding: 90px 0 0 0;
}

.rightHandsApp .heading {
  text-align: left;
}

/* .rightHandsApp .heading p{ font-size: 14px;} */
.rightHandsApp .mt-5 img {
  margin-right: 10px;
}

.appImage {
  text-align: right;
}

.footer {
  background: #9e6dc8;
  padding: 30px 0 10px 0;
  position: relative;
}

.footerLogo {
  margin-right: 70px;
  display: flex;
}

.footerLogo img {
  margin-top: 10px;
}

.footerLinks ul {
  padding: 0;
}

.socialIcon {
  display: flex;
  flex-wrap: wrap;
}

.footerLinks ul li {
  list-style-type: none;
  font-weight: 300;
  line-height: 32px;
}

.footerLinks ul li a {
  color: #ffffff;
  font-size: 14px;
}

.footerLinks ul li a:hover {
  color: #ddbb03;
}

.footerLinks ul li button {
  color: #b793ee;
  font-size: 12px;
  border: 0px;
  background: #432375 !important;
}

.footerLinks ul li button:hover {
  color: #ddbb03;
}

.iconbtn {
  display: flex;
  font-size: 14px;
  margin-bottom: 6px;
  padding: 7px 27px;
  border-radius: 30px;
  border: 1px solid #583492;
  background-color: #3d1f6c;
  color: #9e6dc8;
}

.iconbtn i {
  font-size: 20px;
  margin-right: 10px;
}

.iconbtn:hover {
  background: #ddbb03;
  color: #fff;
  border-color: #ddbb03;
}

.helpQuote {
  position: absolute;
  cursor: pointer;
  padding: 10px;
  z-index: 5555;
  background: #f2e9ff;
  border-radius: 50%;
  right: 100px;
  top: -40px;
}

.footerserviceLinks {
  margin-right: 10%;
}

.footerLocation {
  color: #ffffff !important;
  font-size: 14px;
}

.footerLocation:hover {
  color: #ddbb03 !important;
}

.selectIssue li {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-between;
  font-weight: 300;
  color: #707070;
}

.selectIssue li i {
  font-size: 28px;
  color: #9e9e9e;
}

.selectIssue li:last-child {
  margin-bottom: 0;
}

.relativeselect {
  position: relative;
}

.relativeselect i {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 25px;
  color: #9e9e9e;
}

.phonecodeselect .select {
  width: auto;
  align-items: center;
  margin: 0px auto 0;
  font-size: 16px;
  font-family: HKGrotesk !important;
  font-style: normal;
  color: #1f1f1f;
  padding-left: 10px;

  line-height: 16px;
  position: absolute;
  cursor: context-menu;
  -webkit-user-select: none;
}

.phonecodeselect .select:after {
  content: "\f107";
  font-family: HKGrotesk !important;
  font-style: normal;
  font-style: normal;
  font-size: 30px;
  position: absolute;
  top: 3px;
  left: 90px;
  color: #c4c4c4;
}

.phonecodeselect .mgcode + span img {
  margin-right: 10px;
}

.phonecodeselect .select img {
  border: 1px solid #d5d5d5;
  width: 28px;
}

.phonecodeselect .list {
  display: none;
  width: 400px;
  height: auto;
  margin: -1px auto;
  border-radius: 8px;
  list-style: none;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  position: fixed;
  left: 0;
  right: 0;
}

.phonecodeselect .list li {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 16px;
  font-family: HKGrotesk !important;
  font-style: normal;
  line-height: 60px;
  padding-left: 10px;
  position: relative;
  color: #444;
  cursor: context-menu;
  -webkit-user-select: none;
}

.phonecodeselect .list li img {
  /* position: absolute;
    top: 11px;
    left: 5px; */
  margin-right: 10px;
  width: 28px;
}

.list li {
  color: #707070;
  list-style-type: none;
}

.list li a {
  color: #51565e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 300;
}

.list li i {
  font-size: 28px;
  color: #9e9e9e;
}

.list li:last-child {
  margin-bottom: 0;
}

/*======MyAccount==========*/
p.view_our_policy {
  font-weight: 400;
  justify-content: center;
  color: #60458a;
  cursor: pointer;
}

.timeButtons.view_policy_link {
  margin-bottom: 250px;
}

.phonecodeselect .select {
  width: auto;
  align-items: center;
  margin: 0px auto 0;
  font-size: 16px;
  font-family: HKGrotesk !important;
  font-style: normal;
  color: #1f1f1f;
  padding-left: 10px;
  line-height: 16px;
  position: absolute;
  cursor: context-menu;
  -webkit-user-select: none;
}

.phonecodeselect .select:after {
  content: "\f107";
  font-family: HKGrotesk !important;
  font-style: normal;
  font-size: 30px;
  position: absolute;
  top: 3px;
  left: 90px;
  color: #c4c4c4;
}

.phonecodeselect .mgcode + span img {
  margin-right: 10px;
}

.phonecodeselect .select img {
  border: 1px solid #d5d5d5;
  width: 28px;
}

.phonecodeselect .list {
  display: none;
  width: 400px;
  height: auto;
  margin: -1px auto;
  border-radius: 8px;
  list-style: none;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  position: fixed;
  left: 0;
  right: 0;
}

.phonecodeselect .list li {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 16px;
  font-family: HKGrotesk !important;
  font-style: normal;
  line-height: 60px;
  padding-left: 10px;
  position: relative;
  color: #444;
  cursor: context-menu;
  -webkit-user-select: none;
}

.phonecodeselect .list li img {
  margin-right: 10px;
  width: 28px;
}

.list li {
  color: #707070;
  list-style-type: none;
}

.list li a {
  color: #51565e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 300;
}

.list li i {
  font-size: 28px;
  color: #9e9e9e;
}

.list li:last-child {
  margin-bottom: 0;
}

/*======MyAccount==========*/

.userContainer {
  max-width: 669px;
  margin: 0 auto;
}

.userInfo {
  padding: 5px 0 20px 0;
  min-height: 120px;
  text-align: center;
  background-position: top center;
}

.userInfo img {
  box-shadow: 1px 1px 9px #00000033;
  width: 60px;
  object-fit: cover;
  height: 60px;
  border-radius: 50%;
  margin: 0 auto 20px;
  display: table;
}

.userInfo h2 {
  font-size: 18px;
  margin-bottom: 5px;
}

.accountSettings h3 {
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 14px;
  text-transform: uppercase;
  color: #707070;
  padding: 15px 30px 0 30px;
}

.accountSettings ul {
  margin: 0;
  padding: 0;
}

.accountSettings ul:last-child li:last-child {
  border-bottom: 0;
}

.accountSettings ul li {
  border-bottom: 1px solid #cdcdcd;
}

.accountSettings ul li a {
  padding: 12px 30px;
}

.accountSettings ul li .slogout {
  padding: 35px 30px;
  text-align: center;
  display: block;
  color: #e11d11;
  font-size: 20px;
  font-weight: 400;
}

/* .accountSettings ul li:last-child{ border-bottom: 0;} */
.accountSettings ul li span {
  display: flex;
  align-items: center;
}

.accountSettings ul li span i {
  padding-left: 10px;
}

.personalForm {
  padding: 32px 32px 40px;
}

.inputdiv {
  margin-bottom: 35px;
  position: relative;
}

.inputdiv .form-control {
  min-height: 54px;
  border-radius: 50px;
  padding: 0.66rem 1.2rem;
}

.inputdiv .phonecodeselect .form-control {
  padding-left: 20px;
}

.inputdiv .removeinput {
  display: none;
}

.removeinput {
  background-color: #1f1f1f;
  color: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-align: center;
  line-height: 25px;
  position: absolute;
  right: 15px;
}

.inputdiv:focus + .removeinput {
  display: block;
}

/*input[type="date"]*/

input[type="date"] {
  position: relative;
  padding: 10px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

input[type="date"]:before {
  color: transparent;
  display: block;
  font-family: HKGrotesk !important;
  font-style: normal;
  content: "";
  position: absolute;
  top: 15px;
  right: 18px;
  font-size: 22px;
  color: #aaaaaa;
  width: 22px;
  height: 22px;
  background-size: 22px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.form-select {
  background: url(https://dvdljkkxpxqo3.cloudfront.net/images/arrow-down.png.webp)
    no-repeat !important;
  background-position: 96.5% !important;
}

.addAddressModal .mapContent {
  padding: 0;
}

.addNew {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.editAddress .checkboxItem div:last-child p {
  font-size: 14px;
}

#addAddress .modal-body {
  padding: 0;
}

#editAddress .modal-body {
  padding: 0;
}

.mapContent {
  padding: 20px 25px 30px;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.mapContent .inputdiv i {
  position: absolute;
  left: 15px;
  top: 18px;
  opacity: 0.3;
}

.mapContent .inputdiv {
  z-index: 11;
}

.mapContent .inputdiv .form-control {
  padding-left: 50px;
  border-radius: 8px;
}

.creditCard .card {
  background-image: url(https://dvdljkkxpxqo3.cloudfront.net/images/bgtexture.png.webp);
  background-repeat: no-repeat;
  background-position: 0 bottom;
  min-height: 537px;
}

.creditCard .personalForm {
  padding: 32px 32px 40px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 530px;
}

.creditCard .list-group-item + .list-group-item {
  border-top-width: 1px;
}

.creditCard .list-group {
  margin-top: 1.5rem !important;
  border-radius: 0;
}

.creditCard .list-group-item {
  margin-bottom: 10px !important;
  border-radius: 0.25rem;
  align-items: center !important;
}

.creditCard .inputdiv {
  margin-top: 30px;
}

.creditCard .personalForm h3 {
  font-weight: normal;
  text-align: center;
}

.deleteCard {
  font-size: 18px;
  background: #f1eaf7;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  color: #432375;
  cursor: pointer;
}

.creditCard button#sc_btn_1649745021579 {
  font-size: 16px !important;
  border-radius: 30px !important;
  padding: 13px 30px !important;
}

.form-control.rds-8 {
  border-radius: 8px;
}

.form-control.h-47 {
  min-height: 47px;
}

#addCard .inputdiv {
  margin-bottom: 25px;
}

.appoitmentButton {
  padding: 40px 0 20px 0;
}

.tabBtn {
  border: 0;
  background: none;
  border-radius: 30px;
  padding: 7px 20px;
  min-width: 185px;
  font-weight: 300;
  font-size: 20px;
}

.tabBtn-active {
  background: #432375;
  color: #fff;
}

.tabContent {
  display: none;
}

.tabContentActive {
  display: block;
}

.upcomingItem {
  padding: 20px 0;
  border-bottom: 1px solid #cdcdcd;
  cursor: pointer;
}

.upcomingItem:last-child {
  border-bottom: 0;
}

.upcomingContent {
  padding: 0 32px;
}

.upcomingContent h3 {
  color: #1f1f1f;
  font-weight: normal;
  margin-bottom: 3px;
  cursor: pointer;
}

.upcomingContent h3 span {
  font-size: 16px;
  font-weight: 300;
}

.upcomingContent p {
  margin-bottom: 13px;
  cursor: pointer;
}

.amount p {
  margin-bottom: 0;
  color: #222222;
  font-weight: 500;
}

.amount p span {
  color: #3d1c6f;
}

.btn-confrim {
  color: #fff;
  font-weight: 300;
  background-color: #49a361;
  border-color: #49a361;
  min-height: 30px;
  border-radius: 30px;
  font-size: 14px;
  line-height: 1;
  border: 0;
  min-width: 88px;
}

.verified {
  border: 1px solid #cfcfcf;
  border-radius: 40px;
  padding: 7px 14px;
  line-height: 1;
  color: #4d4d4d;
  max-height: 34px;
  font-size: 15px;
  background: #fff;
  margin-top: 3px;
}

.btn-cancel {
  color: #fff;
  font-weight: 300;
  background-color: #e1190c;
  border-color: #e1190c;
  min-height: 30px;
  border-radius: 30px;
  font-size: 14px;
  line-height: 1;
  border: 0;
  min-width: 88px;
  padding: 0 5px;
}

.appointmentService {
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 0px 6px -5px rgb(67 35 117 / 10%),
    0 0px 10px 5px rgb(67 35 117 / 10%), 0 0px 10px 2px rgb(67 35 117 / 10%);
}

.appointmentService img {
  width: 100%;
  border-radius: 8px;
  height: 190px;
  object-fit: cover;
}

.appointmentDetails h3 {
  margin-bottom: 25px;
}

.appointmentService h3 {
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding-top: 30px;
}

.appointmentService h3 span {
  font-weight: 300;
  font-size: 16px;
  color: #707070;
}

.appointmentService p {
  color: #9e9e9e;
}

.appointmentService p i {
  margin-right: 10px;
  font-size: 20px;
  color: #9e9e9e;
  transform: rotate(90deg);
}

.appointmentService p.verifiedText {
  color: #7bc595;
  margin-bottom: 0;
}

.orderSummary {
  margin: 40px 0 60px 0;
}

.orderSummary h3 {
  margin-bottom: 10px;
}

.orderSummary ul {
  width: 100%;
  list-style: none;
  margin: 0;
}

.orderSummary ul li {
  color: #707070;
  font-weight: 300;
  line-height: 37px;
  display: flex;
  justify-content: space-between;
}

.orderSummary ul li span {
  color: #222222;
}

.orderSummary ul li .total {
  color: #432375;
  font-weight: 700;
}

.btn-light {
  background: #f2e8ff !important;
  color: #9e6dc8 !important;
}

.btn-light:hover {
  background: #ddbb03 !important;
  color: #fff !important;
}

.buttonSec h4 {
  text-align: center;
  font-size: 20px;
  margin-top: 50px;
}

.buttonSec h4 i {
  -webkit-text-stroke: 0.9px #e8e8e8;
  background: #e8e8e8;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 25px;
}

.appointmentDetails .orderSummary ul li .total {
  font-weight: 300;
}

.cancelAppoitment {
  text-align: center;
}

.cancelAppoitment p {
  margin-bottom: 40px;
}

.cancelAppoitment .btn {
  margin-bottom: 30px;
}

.cancelAppoitment p.cancel {
  margin-bottom: 0;
  color: #e1190c;
  font-weight: normal;
  cursor: pointer;
}

.bookingCancel img {
  margin: 0 auto 40px;
  display: block;
}

.bookingCancel h3 {
  margin-bottom: 20px;
}

.modal-header .btn-close {
  outline: 0;
  box-shadow: none !important;
}

.professionalSec {
  margin-bottom: 30px;
}

.professionalSec .serviceSlider {
  min-height: 190px;
  background: none;
  border: 1px solid #d6d6d6;
  padding: 10px;
}

.professionalSec h3 {
  margin-bottom: 3px;
}

.professionalSec .serviceSlider:hover {
  background: #f2e8ff;
  border: 1px solid #3d1c6f;
}

.professionalSec .serviceSlider:hover p {
  color: #51565e;
}

.professionalSec .serviceSlider p {
  min-height: auto;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 4px;
}

.professionalSec .serviceSlider h3 {
  font-size: 14px;
  color: #3d1c6f;
  margin-bottom: 6px;
}

.professionalSec .serviceSlider h3 i {
  color: #9e6dc8;
  padding-left: 5px;
}

.professionalSec .serviceSlider span {
  margin-bottom: 1px;
  display: inline-block;
  height: 110px;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.professionalSec .serviceSlider h3 .fa-star:before {
  margin-right: 5px;
}

.professionalSec .serviceSlider h6 {
  font-size: 14px;
  font-weight: 300;
  color: #3d1c6f;
}

.datesec {
  margin-bottom: 40px;
}

.buttonArea {
  margin-top: 24px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
}

/* .buttonItem{ margin-right: 25px;} */
.buttonItem label {
  position: relative;
  margin: 0 !important;
}

.buttonItem label input {
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
}

.buttonItem label span {
  font-weight: 500;
  color: #51565e;
  cursor: pointer;
  border: 2px solid #d6d6d600;
  min-width: 125px;
  border-radius: 30px;
  padding: 6px 12px;
  display: block;
  padding: 6px 12px;
  text-align: center;
}

.buttonItem input:checked + span {
  color: #fcd500;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;
  border: 2px solid #fcd500;
}

.buttonArea .slick-next {
  background: none !important;
  box-shadow: none !important;
  display: none !important;
}

.buttonArea .slick-prev {
  background: none !important;
  box-shadow: none !important;
  display: none !important;
}

.buttonArea:hover .slick-prev {
  display: block !important;
}

.buttonArea:hover .slick-next {
  display: block !important;
}

#resechduleModal .modal-header {
  border: 0;
}

#resechduleModal span {
  padding: 0;
  background: #9d6dc6;
  color: #fff;
  border-radius: 50%;
  font-size: 50px;
  margin-right: 15px;
  -webkit-text-stroke: 6px #9d6dc6;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
}

#resechduleModal .d-flex {
  align-items: center;
  justify-content: space-between;
}

#resechduleModal .d-flex p {
  margin-bottom: 0;
  color: #707070;
}

#resechduleModal .btn-close {
  position: absolute;
  right: 20px;
  top: 15px;
}

#resechduleModal .modal-header {
  padding: 20px 15px;
}

#resechduleModal .modal-dialog {
  max-width: 410px;
}

.feedbackProfile {
  padding: 50px 0 40px 0;
  border-bottom: 1px solid #cdcdcd;
}

.feedbackProfile img {
  width: 124px;
  height: 124px;
  border-radius: 50%;
  margin: 0 auto 24px;
  display: block;
}

.stars {
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  justify-content: center;
}

.stars label {
  position: relative;
  font-size: 75px;
  margin: 0 20px;
}

.stars label input {
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
}

.stars label i {
  font-weight: 500;
  color: #9e6dc8;
  cursor: pointer;
  display: block;
  text-align: center;
}

.stars .active-star {
  color: #432375;
  font-weight: 500;
}

.active_my_favourites {
  color: #d91818;
}

.active_addtip_button {
  color: #fff !important;
  background: #9e6dc8 !important;
}

.complementsec {
  padding: 0 30px;
}

.complementsec input {
  min-height: 80px;
  font-size: 20px;
  border-radius: 40px;
  padding-left: 100px;
  border: 2px solid #ecddf9;
  background: url(https://dvdljkkxpxqo3.cloudfront.net/images/give-comliment.png.webp)
    no-repeat;
  background-position: 5px;
}

.complementsec textarea {
  min-height: 80px;
  font-size: 20px;
  border-radius: 40px;
  padding-left: 100px;
  border: 2px solid #ecddf9;
  background: url(https://dvdljkkxpxqo3.cloudfront.net/images/give-comliment.png.webp)
    no-repeat;
  background-position: 5px;
}

.complementsec .btn-fav {
  background: #432375 !important;
  min-width: 288px;
  border-color: #432375 !important;
  margin: 30px auto 0;
  display: flex !important;
  align-items: center;
  padding: 4px 10px !important;
  justify-content: center;
  font-size: 22px !important;
  font-weight: 400;
}

.btn-fav span {
  font-size: 45px;
  margin-right: 15px;
}

.addAtip {
  padding: 30px;
}

.addAtipitem {
  text-align: center;
  padding: 30px 0 0 0;
  border-radius: 8px;
  margin: 0 auto 30px;
  max-width: 100%;
}

.yourFeedback h3 {
  font-size: 22px;
}

.tipButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0 30px 0;
}

.tipButton button {
  margin-right: 25px;
  border-radius: 50%;
  font-weight: 500;
  color: #707070;
  font-size: 18px;
  background: #fff;
  border: 1px solid #cdcdcd;
  width: 90px;
  height: 90px;
}

.addAtipitem h3:last-child {
  color: #9e6dc8;
  font-size: 18px;
}

.totalCredit {
  text-align: center;
  line-height: 1;
  padding: 40px 0 35px 0;
}

.totalCredit h3 {
  font-size: 26px;
}

.totalCredit h2 {
  color: #3d1c6f;
  font-weight: bold;
  font-size: 50px;
  padding: 15px 0 16px 0;
}

.totalCredit h6 {
  color: #6537ac;
  font-weight: 300;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.totalCredit h6 span {
  font-size: 30px;
  margin-left: 10px;
}

.payGetMore {
  border: 2px solid#ecddf9;
  background-image: url(https://dvdljkkxpxqo3.cloudfront.net/images/rightbg.png.webp);
  background-position: 50% 0;
  background-repeat: no-repeat;
  padding: 30px 50px;
  max-width: 90%;
  margin: 0 auto;
  border-radius: 8px;
  text-align: center;
}

.payGetMore h2 {
  color: #3d1c6f;
  font-size: 26px;
  margin-bottom: 15px;
}

.payGetMore p {
  color: #707070;
  margin-bottom: 30px;
}

.payGetMore p b {
  font-weight: 600;
}

.payGetMore .btn {
  min-width: 264px;
  font-size: 18px;
}

.payGetMore .dropdown-item {
  background-color: inherit !important;
}

.creditExpiry {
  padding: 35px 32px 45px 32px;
}

.creditExpiry h3 {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 22px;
  color: #222222;
}

.creditExpiry h3 img {
  margin-right: 15px;
}

.creditExpiry p {
  padding-left: 42px;
  margin-bottom: 0;
  color: #707070;
}

.recentTranstion > h3 {
  padding-left: 32px;
}

.recentTranstion > h3 span {
  color: #707070;
  font-size: 16px;
  font-weight: 300;
  padding-left: 15px;
}

.transtionList {
  padding: 20px 32px;
  border-bottom: 1px solid #cdcdcd;
}

.transtionList > h3 {
  color: #1f1f1f;
  font-weight: normal;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transtionList > h3 span {
  font-size: 16px;
  font-weight: 500;
  color: #51565e;
}

.transtionList p {
  margin-bottom: 0;
  color: #707070;
}

.transtionList:last-child {
  border-bottom: 0;
}

.creditDetails .creditExpiry {
  padding-bottom: 0;
}

.recentTranstion center {
  padding: 20px 0;
}

.creditDetails .creditExpiry h4 {
  color: #707070;
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 8px;
}

.creditDetails .creditExpiry h3 {
  margin-bottom: 16px;
  font-size: 18px;
}

.creditDetails .creditExpiry p {
  padding-left: 0;
  margin-bottom: 16px;
}

.creditTotald {
  padding: 30px 32px;
}

.creditTotald h3 {
  font-weight: 500;
}

.creditTotald h3 span {
  color: #1f1f1f;
  font-weight: 500;
}

.transtion .recentTranstion > h3 {
  padding-top: 30px;
  margin-bottom: 10px;
}

.creditPackage .payGetMore {
  border: 0;
}

.creditPackage .payGetMore p {
  margin-bottom: 0;
}

.packageSlider {
  border: 2px solid #ecddf9;
  border-radius: 8px;
  text-align: center;
  position: relative;
  background: rgb(234, 221, 246);
  background: linear-gradient(
    180deg,
    rgba(234, 221, 246, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  margin: 0px 0 20px 0;
  padding: 50px 0 40px 0;
}

.packageSlider span {
  background: #fff;
  border: 2px solid #ecddf9;
  padding: 4px 34px;
  border-radius: 50px;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  width: auto;
  display: table;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 600;
  color: #3d1c6f;
}

.packageSlider h3 {
  font-size: 26px;
  font-weight: bold;
}

.packageSlider h6 {
  color: #9e9e9e;
  font-weight: normal;
  text-decoration: line-through;
  font-size: 20px;
  padding: 22px 0 22px 0;
}

.packageSlider h2 {
  color: #3d1c6f;
  font-size: 35px;
  font-weight: bold;
}

.packageSlider hr {
  background: #dacfe9;
  height: 2px;
  display: table;
  margin: 30px auto;
  width: 217px;
}

.packageSlider p {
  padding: 0 150px;
}

.packageSlider .btn {
  min-width: 264px;
}

.packageSlider h5 {
  color: #51565e;
  padding: 10px 0 0;
}

.faqsec h3 {
  padding-left: 30px;
  padding-bottom: 10px;
  padding-top: 15px;
}

.faqsec .accordion-button {
  padding: 1.2rem 2.25rem 1.2rem 4.25rem;
}

.faqsec .accordion-item {
  border-bottom: 1px solid #cdcdcd;
}

.faqsec .accordion-button::after {
  position: absolute;
  left: 30px;
  background-image: url(https://dvdljkkxpxqo3.cloudfront.net/images/plus.png.webp);
}

.faqsec .accordion-button:not(.collapsed)::after {
  background-image: url(https://dvdljkkxpxqo3.cloudfront.net/images/minus.png.webp);
}

.faqsec .accordion-button {
  color: #222;
}

.faqsec .accordion-body {
  padding: 1rem 1.875rem;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
  margin-bottom: 5px;
}

.faqsec .accordion-body p {
  margin-bottom: 0;
}

.faqPage.faqsec .accordion-button {
  padding: 1.5rem 2.25rem 1.5rem 4.25rem;
}

/* .superPackage{border: 2px solid rgb(193, 255, 178);   background: rgb(193, 255, 178);
background: linear-gradient(180deg, rgba(193, 255, 178) 0%, rgb(247, 255, 230) 100%);} */
.basicPakage {
  border: 2px solid #c0eff6;
  background: rgb(190, 238, 247);
  background: linear-gradient(
    180deg,
    rgb(190, 238, 247) 0%,
    rgb(247, 255, 230) 100%
  );
}

.creditPackage .serviceBanner .container {
  padding: 0;
}

.helpItem ul {
  padding: 0 0 0 18px;
}

.accordion-body h1 {
  font-size: 1.75rem !important;
}

#root .card .accordion-body h2 {
  font-size: 1.75rem !important;
}

.getFreebox {
  border-radius: 8px;
  border: 2px solid #d6b3f4;
  padding: 55px 55px 90px;
  text-align: center;
  background: url(https://dvdljkkxpxqo3.cloudfront.net/images/bg4.png.webp)
    no-repeat;
  background-position: 100% 100%;
}

.getFreebox h3 {
  color: #8c59b7;
  margin-bottom: 20px;
  font-weight: normal;
}

.getFreebox p {
  color: #9e9e9e;
  margin-bottom: 0;
  line-height: 28px;
}

.getFreebox h6 {
  padding: 30px 0 30px 0;
}

.getFreebox .btn-light {
  border-radius: 30px;
  padding: 14px 30px;
  font-weight: 500;
  font-size: 16px;
  min-width: 188px;
}

.EarnedItem {
  display: flex;
  margin: 35px 0 0 0;
  flex-wrap: wrap;
}

.creditEarned {
  margin-right: 60px;
  margin-bottom: 30px;
}

.creditEarned p {
  color: #707070;
}

.helpItem p {
  display: flex;
  align-items: center;
  color: #707070;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}

.helpItem p span {
  color: #3d1c6f;
}

.helpPage .upcomingContent {
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #cdcdcd;
}

.helpPage .verified {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
}

.helpPage .verified img {
  height: 20px;
}

.helpPage .verified p {
  margin: 0 15px 0 10px;
  color: #707070;
  font-size: 14px;
  font-weight: 300;
}

.helpPage .verified span {
  display: flex;
  align-items: center;
}

.helpPage .verified span i {
  color: #ffae00;
  font-size: 20px;
  margin-right: 5px;
}

.termsLink {
  display: flex;
  align-items: center;
  justify-content: center;
}

.helpPage .termsLink a {
  font-size: 14px;
  color: #707070;
  margin: 0 10px;
  padding: 38px 0 15px 0;
}

.helpPage2 .personalForm {
  padding-bottom: 20px;
}

.helpPage .termsLink a {
  padding: 18px 0 15px 0;
}

.termsContainer {
  max-width: 100%;
}

.TermItem ol {
  padding-left: 20px;
}

.TermItem ol li {
  margin-top: 0;
  font-weight: 300;
  color: #51565e;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 1rem;
}

.TermItem ul {
  padding-left: 20px;
}

.TermItem ul li {
  margin-top: 0;
  font-weight: 300;
  color: #51565e;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 1rem;
}

/*23/03/2023*/
.cleanerSummery {
  margin: 40px 0 30px 0;
  color: #432375;
}

.cleanerSummery h3 {
  margin-bottom: 10px;
}

.cleanerSummery ul {
  width: 100%;
  list-style: none;
  margin: 0;
}

.cleanerSummery ul li {
  color: #707070;
  font-weight: 300;
  line-height: 37px;
  display: flex;
  justify-content: space-between;
}

.cleanerSummery ul li span {
  color: #222222;
}

.cleanerSummery ul li .total {
  color: #432375;
  font-weight: 700;
}

.appointmentSummery {
  margin: 40px 0 30px 0;
  color: #432375;
}

.appointmentSummery h3 {
  margin-bottom: 10px;
}

.appointmentSummery ul {
  width: 100%;
  list-style: none;
  margin: 0;
}

.appointmentSummery ul li {
  color: #707070;
  font-weight: 300;
  line-height: 37px;
  display: flex;
  justify-content: space-between;
}

.appointmentSummery ul li span {
  color: #222222;
}

.appointmentSummery ul li .total {
  color: #432375;
  font-weight: 700;
}

/*address*/
.editAddress .checkboxItem div:nth-child(1) {
  width: 90%;
}

.editAddress .checkboxItem div:nth-child(2) p {
  cursor: pointer;
  text-align: right;
  margin: 0;
}

.editAddress .checkboxItem div:nth-child(2) p:nth-child(2) {
  color: #e1190c;
}

.editAddress .checkboxItem {
  margin-bottom: 10px;
}

.editAddress .checkboxItem .form-check label {
  width: 90%;
  line-height: 1.2;
}

.swal2-shown .swal2-popup {
  max-width: 480px !important;
}

.swal2-shown .swal2-title {
  color: #1f1f1f;
  font-weight: 500;
  font-size: 1.5em;
}

.swal2-shown .swal2-html-container {
  font-weight: 300;
}

.swal2-shown .swal2-actions .swal2-styled {
  min-width: 180px;
  border-radius: 30px;
  padding: 12px 30px;
  font-weight: 500;
  font-size: 16px;
  font-weight: normal;
  box-shadow: none !important;
}

.swal2-shown .swal2-styled.swal2-confirm {
  background-color: #432375 !important;
}

.swal2-shown .swal2-icon.swal2-warning {
  border-color: #432375;
  color: #432375;
}

.swal2-shown .swal2-styled.swal2-cancel {
  background: #f2e8ff !important;
  color: #9e6dc8 !important;
}

.swal2-shown .swal2-actions {
  margin: 1.25em auto 1em;
}

.gmnoprint {
  bottom: 265px !important;
  z-index: 222222 !important;
}

.gmnoprint .gm-style-mtc {
  top: 70px;
}

.gm-fullscreen-control {
  top: 70px !important;
}

/*ModalPopup*/
.serviceDetailsModal {
  cursor: pointer;
}

.modal .modal-title {
  font-size: 18px;
}

.modal-open .modal.show {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.modal.show .modal-dialog {
  width: 100%;
  height: 100%;
}

/*=============Frequency=============*/

table {
  border-spacing: 0;
  border-radius: 6px 6px 6px 6px;
  -moz-border-radius: 6px 6px 6px 6px;
  -webkit-border-radius: 6px 6px 6px 6px;
}

table th:first-child {
  border-radius: 6px 0 0 0;
  -moz-border-radius: 6px 0 0 0;
  -webkit-border-radius: 6px 0 0 0;
}

table th:last-child {
  border-radius: 0 6px 0 0;
  -moz-border-radius: 0 6px 0 0;
  -webkit-border-radius: 0 6px 0 0;
}

.stepsFromPage .pt-5 {
  padding-top: 40px !important;
}

.location {
  text-align: center;
  font-weight: 600;
}

.location p {
  font-weight: 400;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #60458a;
}

.location p span {
  font-size: 32px;
  padding-left: 8px;
  color: #432375;
  margin-bottom: 20px;
}

.step-wizard {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 14px;
}

.item-container {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
}

.item-container .item.is-active {
  width: 120px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.item-container .tag {
  -webkit-box-align: center;
  z-index: 111;
  align-items: center;
  background-color: #f2e9ff;
  border-radius: 50%;
  color: #222222;
  display: -webkit-inline-box;
  display: inline-flex;
  font-size: 1rem;
  width: 1.875em;
  height: 1.875em;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: 10px;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
}

.item-container .item {
  width: 120px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.helper-text-item {
  font-size: 16px;
  font-weight: 500;
  color: #222222;
  margin: 0;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25rem;
  letter-spacing: normal;
}

.item-container .divider {
  height: 3px;
  border-radius: 3px;
  background-color: #f2e9ff;
  margin-left: -20px;
  margin-right: -20px;
  width: 180px;
  margin-top: -50px;
}

.Frequency .btn-back {
  display: none;
}

.is-active .divider {
  background-color: #dac9f1;
}

.is-active .marker {
  background-color: #432375;
  color: #fff !important;
}

.is-active .helper-text-item {
  color: #fff;
}

.Service .is-flex:nth-child(2) .divider {
  background-color: #dac9f1;
}

.Service .is-flex:nth-child(2) .marker {
  background-color: #432375;
  color: #fff !important;
}

.Service .is-flex:nth-child(2) .helper-text-item {
  color: #fff;
}

.Service .is-flex:nth-child(1) .divider {
  background-color: #dac9f1;
}

.isDone .marker {
  background-color: #60b37d;
  color: #fff !important;
}

.isDone .helper-text-item {
  color: #fff;
  position: relative;
  font-size: 0;
}

.isDone .helper-text-item:after {
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f00c";
  position: absolute;
  left: -10px;
  right: 0;
  font-size: 20px;
  color: #fff;
}

.stepDate .timeButtons + p {
  margin-bottom: 300px;
}

.divsticky {
  background-color: #fff;
  padding: 0 0 10px 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.servicePackage {
  z-index: 11;
  position: -webkit-sticky;
  position: sticky;
  bottom: -40px;
  margin-bottom: 0;
}

.servicePackage .actionButton {
  background: #fff;
  border-radius: 0 0 8px 8px;
  margin: -7px 0 40px 0;
  top: auto;
  border: 1px solid #cdcdcd;
  padding-bottom: 30px;
}

.stepsContent .row .col-lg-7 {
  display: flex;
}

/* .stepsContent  .row .col-lg-5{ display: flex; } */
.actionBTn.stickyhead {
  position: fixed;
  top: auto;
  bottom: 0;
  margin-bottom: 0;
  max-width: 658px;
}

/* .stepDate + .servicePackage{ position: inherit;}
.Payment + .servicePackage{ position: inherit;}
.Payment + .servicePackage .actionButton{ top:-115px; border: 0; margin: 0;  background: none;
border-top: 1px solid #cdcdcd;}
.stepDate + .servicePackage .actionButton{ top:-115px; border: 0; margin: 0;  background: none;
border-top: 1px solid #cdcdcd;} */
.step-wizard .item-container:nth-child(1) .step-title {
  width: auto;
}

.step-wizard .item-container:nth-child(4) .step-title {
  width: auto;
}

.w-full {
  width: 100%;
}

.card {
  border-radius: 8px;
  width: 100%;
  border: 1px solid #cdcdcd;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 5%),
    0 2px 2px 0 rgb(0 0 0 / 5%);
}

.stepsCard {
  padding: 32px 0 0 0;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 480px;
  display: flex;
}

.stepsCard .heading {
  text-align: left;
  margin-bottom: 35px;
}

/* .stepService .card{    min-height: 740px;}
.Payment .card{    min-height: 740px;} */

.servicedetail .modal-body .modal-body {
  max-height: 500px;
  overflow-y: auto;
}

.servicedetail .modal-body .modal-body ul {
  padding-left: 18px;
  padding-top: 5px;
}

.ServiceStpes .item-container:nth-child(3) .step-title {
  width: auto;
}

.ServiceStpes .item-container:nth-child(1) .step-title {
  width: 180px;
}

.actionBTn .btn:disabled {
  opacity: 0.3;
  background-color: #432375;
  border-color: #432375;
}

.checkboxItem {
  margin-bottom: 34px;
}

.checkboxItem label {
  color: #222222;
  font-weight: 400;
}

.checkboxItem .form-check {
  display: flex;
  align-items: center;
}

.checkboxItem .form-check input {
  margin-top: 0;
  margin-right: 20px;
  cursor: pointer;
}

.checkboxItem .form-check label {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 18px;
  line-height: 1;
}

.checkboxItem p {
  padding-left: 50px;
  color: #707070;
  padding-top: 4px;
}

.address p {
  margin-bottom: 0.5rem !important;
  padding-top: 0 !important;
}

.stepsCard .checkboxSec {
  padding-left: 28px;
  padding-right: 28px;
}

.checkboxItem .form-check label span {
  background: #f2e9ff;
  margin-left: 10px;
  color: #9e6dc8;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 14px;
}

.checkboxItem .form-check label i {
  margin-right: 5px;
}

.actionButton {
  margin-bottom: -60px;
  padding: 30px 20px 60px 20px;
  position: relative;
  top: -115px;
  border-top: 1px solid #cdcdcd;
  display: flex;
  max-width: 100%;
  justify-content: space-between;
}

.actionButton .btn {
  min-width: 154px;
}

.rightMe {
  justify-content: space-between;
}

.leftMe {
  justify-content: start;
  white-space: pre-line;
}

.card-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 19px 25px;
  border-bottom: 1px solid #cdcdcd;
}

.card-content p {
  margin-bottom: 0;
  color: #222222;
  font-weight: normal;
  line-height: 1;
  font-size: 16px;
}

.card-content p + span {
  color: #707070;
  font-weight: 300;
  width: 100%;
  padding-top: 5px;
}

.card-content ul {
  width: 100%;
  list-style: none;
  margin-top: 15px;
  margin-bottom: 0;
}

.card-content ul li {
  color: #707070;
  font-weight: 300;
  line-height: 37px;
  display: flex;
  justify-content: space-between;
}

.card-content ul li span {
  color: #222222;
}

.card-content ul li .total {
  color: #432375;
  font-weight: 700;
}

.dateTime,
.serviceDetails {
  display: none;
}

.card-content:last-child {
  border-bottom: 0;
}

.stepService .heading {
  margin-bottom: 27px;
}

.stepService .heading img {
  margin-left: 5px;
}

.stepService .circleInput label span {
  width: 42px;
  min-width: 42px;
  border-radius: 50%;
}

.stepService .buttonItem label {
  margin-right: 25px !important;
}

.stepService .buttonItem:not(.circleInput) label span {
  padding: 6px 16px;
}

.btn-back {
  border: 0;
  background: none;
  padding: 0;
}

.btn-back:disabled {
  visibility: hidden;
}

.buttonItem textarea {
  border: 2px solid #d6d6d6;
  width: 100%;
  border-radius: 8px;
  min-height: 120px;
  padding: 15px;
}

.creditfirst .form-check {
  padding-left: 0;
}

.creditfirst .form-check h3 img {
  margin-right: 20px;
}

.creditfirst .form-check h3 span {
  margin-left: 10px;
  color: #8753b3;
  font-weight: 300;
}

.cardsec {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.cardsec h3 {
  color: #707070;
  text-transform: uppercase;
  margin-top: 25px;
  margin-bottom: 25px;
}

.cardsec h3 img {
  margin-left: 5px;
  margin-bottom: 4px;
}

#root .Payment .cardsec Button {
  background-color: #fff !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.cardsec input {
  border-radius: 30px;
  border: 2px solid #d6d6d6;
  min-height: 58px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.Payment .checkboxItem p a {
  color: #707070;
  text-decoration: underline;
}

.Payment .checkboxItem p {
  color: #9e6dc8;
  margin-bottom: 0;
}

.addVoucher {
  border: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  background: #e7e7e7;
  color: #707070;
  border-radius: 30px;
  padding: 5px 12px;
}

.addVoucher i {
  font-size: 25px;
  padding-left: 8px;
}

.modal-body .table {
  border: 1px solid #cdcdcd;
}

.modal-body th {
  border-right: 1px solid #d7d5d5;
  font-size: 14px;
  padding: 10px 15px !important;
}

.modal-body tr td {
  border-right: 1px solid #d7d5d5;
  font-size: 14px;
  font-weight: 300;
  padding: 10px 15px !important;
}

.modal-body .table th {
  padding: 10px 15px;
}

.modal-body .table > :not(:first-child) {
  border-top: 1px solid #cdcdcd;
}

#info .modal-body ul {
  padding: 15px 0 0 30px;
  font-weight: 300;
  font-size: 14px;
}

#info .modal-body ul li {
  line-height: 27px;
}

#covidModal ul {
  padding-left: 20px;
  padding-top: 0;
}

#covidModal .modal-body ul li {
  line-height: 27px;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 300;
}

#info h3 {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #51565e;
}

#info h3 img {
  margin-right: 8px;
}

#covidModal .form-check {
  display: flex;
  align-items: center;
}

#covidModal .form-check input {
  margin-top: 0;
  margin-right: 10px;
}

#covidModal .form-check label {
  margin-bottom: 0;
  font-weight: 600;
  color: #51565e;
}

.selctAddressItem {
  padding: 15px 25px;
  border-bottom: 1px solid #cdcdcd;
  cursor: pointer;
}

.selctAddressItem .checkboxItem {
  margin-bottom: 0;
}

.selctAddressItem p {
  margin-bottom: 0;
}

.selctAddressItem:last-child {
  padding: 25px 30px 15px 30px;
  border-bottom: 0;
}

.selctAddressItem:last-child p {
  text-align: center;
  font-weight: 300;
  font-size: 14px;
}

.selctAddressItem:last-child p span {
  display: block;
  color: #432375;
  width: auto;
  height: auto;
  padding: 0;
  background: none;
  opacity: 1;
  font-weight: 500;
}

.selctAddressItem h3 i {
  margin-right: 10px;
  font-size: 20px;
}

.checkboxSec .selctAddressItem h3 i {
  background: #432375;
  border-radius: 50%;
  min-width: 40px;
  text-align: center;
  color: #fff;
  min-height: 40px;
  line-height: 40px;
}

.selctAddressItem h3 {
  color: #432375;
  font-size: 18px;
  font-weight: 500;
}

.priceTotal {
  display: none;
}

.ServiceStpes .step-wizard {
  margin-bottom: 30px;
}

.ServiceStpes .item-container .divider {
  width: 425px;
  margin-top: -60px;
}

.ServiceStpes .step-wizard .step-title {
  margin-bottom: 0;
  min-height: 52px;
}

/*serviceModal*/
#serviceinfo .modal-body {
  height: 560px;
  overflow-y: auto;
}

#serviceinfo .modal-body tr td {
  font-weight: 300;
}

#serviceinfo .modal-body h3 {
  font-size: 16px;
  color: #51565e;
  margin: 15px 0 12px 0;
  font-weight: 500;
}

#serviceinfo .modal-body h3 img {
  margin-right: 9px;
}

#serviceinfo .modal-body ul {
  padding: 0px 0 0px 14px;
  margin: 0;
}

#serviceinfo .modal-body ul li {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 15px;
  list-style-type: none;
  color: #51565e;
}

#serviceinfo ul li::before {
  content: "\2022";
  color: #cccccc;
  font-weight: bold;
  font-size: 16px;
  display: inline-block;
  width: 14px;
}

/*=============Frequency=============*/

/*=============Service=============*/
.hairService {
  padding: 20px;
}

.hairService .tabBtn {
  min-width: 90%;
  font-weight: 500;
  padding: 6px;
  font-size: 15px;
  text-align: center;
  border-radius: 30px;
  color: #51565e;
  cursor: pointer;
  border: 2px solid #d6d6d6;
}

.tabBtn-active {
  background: #432375;
  border: 2px solid #432375 !important;
  color: #fff !important;
}

.vertical-center-3 .slick-next {
  display: none !important;
}

.vertical-center-3 .slick-prev {
  display: none !important;
}

.vertical-center-3:hover .slick-prev {
  display: block !important;
}

.vertical-center-3:hover .slick-next {
  display: block !important;
}

.professionalSec .owl-stage-outer {
  padding: 0 1px;
}

.hairService .tabContent {
  padding: 32px 0 0 0;
}

.hairService .tabContent > h3 {
  margin-bottom: 15px;
}

.hairService .tabContent img {
  border-radius: 8px;
  height: 210px;
  object-fit: cover;
}

.hairService .tabContent .amount p {
  font-weight: 400;
  color: #3d1c6f;
}

.hairService .tabContent .amount p span {
  font-weight: 300;
  color: #707070;
  text-decoration: line-through;
  padding-left: 10px;
}

.hairService .tabContent .amount .btn {
  padding: 5px 21px;
}

.buttonadd .addBtn {
  width: auto;
  padding: 10px 30px;
}

.servicedetail img {
  height: 200px;
  object-fit: cover;
}

/* .servicepopopList{ height: 250px; overflow-y: auto;} */

.serviceItem {
  padding: 20px 0;
  border-bottom: 1px solid #cdcdcd;
}

.serviceItem h3 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

/* .serviceItem:last-child{ border-bottom: 0;} */

.vertical-center-3 .slick-slide:first-child {
  margin-left: 0;
}

.vertical-center-3 .slick-slide {
  margin-left: 0px;
}

.button-with-counter {
  background: #fff;
  border: 2px solid #432375;
  border-radius: 30px;
  padding: 2px 12px;
  min-width: 90px;
  color: #432375;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

i.fa.fa-minus {
  margin-right: 10px;
}

.cartItemCountBtn.disabled {
  color: #9679c4;
}

.button-with-counter.manageCardItemBtnHide {
  display: none !important;
}

button.btn.btn-primary.addBtn.addItemBtnHide {
  display: none;
}

span.dia.disabled_date {
  background-color: #f3eeee;
  cursor: no-drop !important;
  pointer-events: auto !important;
  color: #b6b6b6 !important;
  border: 2px solid #dbd9df;
}

.button-with-counter span {
  color: #432375;
  font-size: 20px;
  margin-right: 12px;
}

.buttonOpen .button-with-counter {
  display: flex;
}

.buttonOpen .addBtn {
  display: none;
}

.fixedAction {
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 55;
  left: 0;
  right: 0;
  display: flex;
  max-width: 657px;
  border: 1px solid #cdcdcd;
  background: #fff;
  padding-bottom: 30px;
  padding-right: 20px;
}

#ServicePopup .button-with-counter {
  display: flex;
  max-width: 120px;
  margin: 30px auto 0;
}

#ServicePopup h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

#ServicePopup h3 p {
  margin-bottom: 0;
  color: #432375;
}

#ServicePopup h3 p span {
  font-weight: 300;
  color: #707070;
  text-decoration: line-through;
  padding-left: 10px;
}

#ServicePopup h4 {
  font-size: 16px;
}

#ServicePopup .modal-body ul {
  padding: 15px 0 0 30px;
  font-weight: 300;
  font-size: 14px;
}

#ServicePopup .modal-body ul li {
  line-height: 27px;
}

/* p.newAddresDetails {margin-top: -8px !important;} */
.homeCleanBanner {
  position: relative;
}

.homeCleanBanner:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 100%;
  top: 0;
  border-radius: 8px;
}

.homeCleanBanner img {
  width: 100%;
  height: 479px;
  object-fit: cover;
  border-radius: 8px;
}

.homeCleanBanner .bannerContent {
  position: absolute;
  top: 0;
  padding-left: 80px;
  padding-top: 120px;
  height: 100%;
  color: #fff;
  display: flex;
  z-index: 1;
  flex-wrap: wrap;
  align-items: center;
}

.homeCleanBanner .bannerContent h1 {
  margin-bottom: 5px;
  color: #fff;
  font-size: 32px;
}

.homeCleanBanner .bannerContent p {
  color: #fff;
  font-size: 18px;
}

.homeCleanBanner .bannerContent .btn {
  border-radius: 30px !important;
  min-width: 154px;
}

.ServicePageContent {
  padding: 40px 0 0 0;
}

.ServicePageContent h2 {
  margin-bottom: 10px;
  font-size: 30px;
}

.ServicePageContent h3 {
  margin-bottom: 10px;
}

.faqtab .tabBtn {
  min-width: auto;
  font-size: 18px;
  font-weight: 600;
  margin-right: 20px;
  color: #707070;
  line-height: 34px;
}

.faqtab {
  border-bottom: 1px solid #cdcdcd;
  text-align: center;
}

.faqtab .tabBtn {
  background: #fff;
  border: 0 !important;
  border-bottom: 1px solid #cdcdcd0d !important;
  border-radius: 0;
}

.faqtab .tabBtn-active {
  background: #fff;
  color: #432375 !important;
  border: 0 !important;
  border-bottom: 1px solid #60458a !important;
  border-radius: 0;
}

/*Blog*/

.blogPAGE {
  padding: 40px 0 20px 0;
}

.blogHeader .navbar {
  background-color: #f5eefe;
  border-bottom: 2px solid #efe5fd;
}

.blogpageBanner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blogpageBanner .blogImage {
  max-width: 630px;
  background-image: url(https://dvdljkkxpxqo3.cloudfront.net/images/blogm.jpg.webp);
  border-radius: 8px 0 0px 8px;
  height: 365px;
  width: 100%;
  background-size: cover;
}

.blogPublished {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.blogpageBanner .blogImage img {
  min-height: 365px;
  object-fit: cover;
  border-radius: 8px 0px 0px 8px;
}

.publishlogo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f5f1ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.publishlogo img {
  width: 45px;
}

.blogpageBanner .blogbnrContent {
  display: flex;
  align-items: center;
  border-radius: 0 8px 8px 0;
  border: 1px solid #cdcdcd;
  border-left: 0;
  width: 391px;
  min-height: 365px;
}

.publishDate h4 {
  font-size: 14px;
}

.publishDate p {
  font-size: 13px;
  margin-bottom: 0;
}

.blogbnrContent {
  padding-left: 35px;
}

.blogList {
  padding-top: 40px;
  border-radius: 0 0 8px 8px;
}

.blogListContent {
  margin-bottom: 30px;
}

.blogListContent img {
  height: 172px;
  border-radius: 8px 8px 0 0px;
  object-fit: cover;
}

.blogListContent {
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  height: auto;
}

.blogListContent h3 {
  margin-bottom: 10px;
}

.blogListContent .publishlogo img {
  height: 16px;
}

.blogFooter {
  background: #ededed;
  padding: 24px 0;
}

.blogFooter ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.blogFooter ul li {
  list-style-type: none;
  font-size: 14px;
  margin-right: 20px;
}

.blogDetail h2 {
  margin-bottom: 15px;
  margin-top: 10px;
}

.blogDetailImage img {
  border-radius: 8px;
  width: 100%;
  min-height: 340px;
}

.blogDetailImage {
  margin-top: 15px;
}

.blogDetail .publishDate p {
  padding-left: 0;
}

.blogDetail .socailIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 50px;
}

.blogDetail .socailIcon span {
  width: 25px;
  height: 25px;
  background-color: #838383;
  border-radius: 50%;
  margin-right: 10px;
  text-align: center;
  line-height: 25px;
  color: #fff;
  font-size: 14px;
}

.blogDetail .socailIcon span:hover {
  background-color: #3d1c6f;
}

.blogDetail .socailIcon span:last-child {
  margin-right: 0;
}

.blogDeatilList {
  border-top: 2px solid #e7e7e7;
  margin-top: 40px;
}

.blogDeatilList .blogPublished {
  margin-top: 0;
}

.listItem {
  border-bottom: 1px solid #e7e7e7;
  padding: 15px 0;
}

.listItem:last-child {
  border-bottom: 0;
}

.listItem .col-lg-2 img {
  min-height: 120px;
  border-radius: 8px;
  object-fit: cover;
}

.listItem .col-lg-10 p {
  padding-right: 20px;
}

.listItem h3 {
  margin: 12px 0;
}

.listItem .col-lg-10 p:last-child {
  margin-bottom: 0;
}

.listItem .col-lg-10 p span {
  font-size: 12px;
}

.owl-theme .owl-nav {
  margin-top: 0 !important;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  display: flex;
  color: #8e7daa;
  justify-content: space-between;
}

.owl-theme .owl-nav [class*="owl-"] {
  background: none !important;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  color: #8e7daa !important;
}

/* service package date slot */
.dateSlot .owl-stage-outer .owl-stage {
  width: 1765px !important;
}

/*  */
/* reschedule arabic date slot */
.rescedule_ar_dateSlot .owl-stage-outer .owl-stage {
  width: 2195px !important;
}

/*  */
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  margin: 0;
  position: absolute;
  top: 0;
  outline: 0 !important;
}

.owl-carousel .owl-nav .owl-next {
  right: -15px;
  left: auto;
}

.owl-carousel .owl-nav .owl-prev {
  right: auto;
  left: -15px;
}

.professionalSec .owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  top: 50%;
}

.professionalSec .owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  top: 50%;
}

.timeButtons .owl-carousel .owl-nav button.owl-next {
  top: 0;
}

.timeButtons .owl-carousel .owl-nav button.owl-prev {
  top: 0;
}

.timeButtons .owl-carousel:hover .owl-nav {
  display: block;
}

.timeButtons .buttonArea {
  overflow: visible;
}

.hairService .owl-carousel .owl-nav {
  display: none;
}

.hairService .owl-carousel:hover .owl-nav {
  display: block;
}

.servicethumb img {
  height: 115px;
}

.DisinfectionTabs .serviceItem p {
  margin-bottom: 0;
}

.disinTabLink .item {
  margin-right: 25px;
}

.disinTabLink .item .tabBtn {
  padding: 6px 12px;
  min-width: 120px;
}

.react-tel-input .form-control {
  font-size: 16px !important;
  height: 47px !important;
  font-size: 16px !important;
  border: 1px solid #cfcfcf;
  width: 100% !important;
}

.react-tel-input .flag-dropdown {
  border-right: 0 !important;
  border: 1px solid #cacaca !important;
  border-radius: 8px 0 0 8px !important;
}

.inputdiv .react-tel-input .flag-dropdown.open .selected-flag {
  background: none !important;
}

.inputdiv .react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: none !important;
}

#otpVerify .phone-number {
  font-weight: 600;
  color: #222222;
  font-size: 16px;
}

.navbar .dropdown-menu {
  left: auto !important;
}

#profileForm .react-tel-input .form-control {
  border-radius: 30px !important;
}

#profileForm .react-tel-input .flag-dropdown {
  border-radius: 30px 0 0 30px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: none !important;
}

.PageLoader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #f2e9ffed;
  z-index: 99999999;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.PageLoader .spinner-border {
  position: static;
  text-align: center;
}

.PageLoader p {
  margin-bottom: 0;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 500;
}

.PageLoader .spinner-border {
  width: 2.2rem;
  height: 2.2rem;
}

.form input[type="text"],
.form input[type="tel"],
.form input[type="email"],
.form input[type="password"],
.form textarea {
  border-radius: 8px !important;
}

.checkout-form-container .checkout-form-left {
  margin: 0 auto 0px auto !important;
}

.checkout-form-container .box {
  border-radius: 0 !important;
}

.checkout-single,
.checkout-loading-container {
  max-width: 366px;
  margin-top: 20px;
  padding: 0;
  background: #fff;
  border-radius: 8px;
}

.checkout-single .box {
  box-shadow: inherit !important;
}

.checkout-form .card-input .adj-input.first input {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.checkout-form .btn-order {
  padding: 10px;
  width: 100%;
  border-radius: 60px;
  font-size: 22px;
  min-height: 60px;
}

.dia_semana label {
  display: block;
  cursor: pointer;
  text-align: center;
  margin-bottom: 0;
}

.dia_semana label input {
  position: absolute;
  height: 100%;
  width: 100%;
  visibility: hidden;
  left: 0;
}

.dia_semana input:checked + span + .dia {
  color: #000;
  background: #fcd500;
  border: 2px solid #fcd500;
}

.dia_semana span {
  display: block;
  padding: 0;
  line-height: 1;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 300;
  color: #707070;
}

.dia_semana .dia {
  text-align: center;
  line-height: 41px;
  color: #51565e;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  font-weight: 500;
  border: 2px solid #d6d6d6;
  margin: 0 auto;
}

.owl-item .mes {
  display: none;
}

.stepsContent .datesec .owl-nav button {
  top: 30%;
}

.mapToggle .inputdiv {
  margin-bottom: 0;
}

/*SwitchButton*/
.switch {
  position: relative;
  display: inline-block;
  width: 50px !important;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.checkboxItem .form-check .switchslide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2e9ff !important;
  transition: 0.4s;
  margin-left: 0 !important;
  border-radius: 35px !important;
}

.checkboxItem .form-check input:checked + .switchslide:before {
  left: -5px;
}

.checkboxItem .form-check .switchslide:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.checkboxItem .form-check input:checked + .switchslide {
  background-color: #432375 !important;
}

input:checked + .switchslide:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switchslide.round {
  border-radius: 34px;
}

.switchslide.round:before {
  border-radius: 50%;
}

.Payment .checkboxItem .switchBtn {
  padding-left: 0;
}

.translated-rtl .checkboxItem .switchBtn .form-check label {
  width: auto;
}

.switchFlex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.switchFlex label img {
  margin-right: 20px;
}

/*Success*/
.successPage {
  background: #f8f3ff;
  padding: 50px 0;
}

.successCard {
  padding-top: 60px;
  border-radius: 8px;
  max-width: 100%;
  margin: 0 auto;
  min-height: 300px;
  box-shadow: 1px 2px 10px #e0d3f3;
  text-align: center;
  background-color: #fff;
  background-position: center -30px;
}

.successCard .checkicon {
  padding: 12px;
  background: #432375;
  color: #432375;
  text-align: center;
  border-radius: 50%;
  font-size: 30px;
  -webkit-text-stroke: 2px;
  -webkit-text-fill-color: #fff;
}

.successCard h2 {
  margin-bottom: 10px;
}

.successCard h3 {
  color: #432375;
}

.successCard ul li {
  flex-wrap: wrap;
}

.successCard ul li strong {
  font-weight: bold;
  color: #432375;
}

.successCard p {
  margin: 20px 0;
}

.successCard .btn {
  margin: 20px 0;
}

.lifeEasy img {
  max-width: 120px;
  max-height: 120px;
  min-height: 120px;
}

.payGetMore .dropdown-item:hover {
  background: none !important;
  background-color: inherit !important;
}

.transtionList .dropdown-item {
  background: none !important;
  padding-left: 0 !important;
}

.transtionList .dropdown-item:hover {
  background: none !important;
  color: #ddbb03;
}

.modal-body .successPage {
  height: auto;
  overflow-y: auto;
  padding: 0;
}

.modal-body .successPage .successCard {
  border-radius: 0;
}

/*skipButton*/
.btn_skip {
  background-color: #fff;
  border: 2px solid #432375;
  color: #432375;
}

.addRating .btn {
  display: flex;
  padding: 11px 30px;
  margin: 0 auto;
  min-width: 190px;
  align-items: center;
  justify-content: center;
}

.addRating .btn span {
  font-size: 26px;
}

.fiveStar i {
  margin: 0 2px;
  color: #ffffff;
  font-size: 18px;
  background: #01b57a;
  padding: 5px;
}

/*RatingPopup*/
.ratingArea {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 10px 0;
}

.ratingDiv {
  text-align: center;
  margin: 0 15px;
}

.ratingDiv p {
  margin-bottom: 5px;
  font-size: 14px;
}

.ratingDiv h4 {
  font-size: 26px;
}

.ratingDiv h4 span {
  color: #3d1f6c;
}

.ratingDiv h4 span i {
  margin-right: 2px;
}

.transtionList p a {
  color: #3d1c6f;
}

.payGetMore .dropdown-item:hover {
  background-color: inherit !important;
}

.transtionList .dropdown-item {
  background: none !important;
  padding-left: 0 !important;
  background-color: inherit !important;
}

.transtionList .dropdown-item:hover {
  background: none !important;
  color: #ddbb03;
}

.modal-body .successPage {
  padding: 0 !important;
}

.modal-body .successPage .successCard {
  border-radius: 0 !important;
  padding-top: 20px;
  box-shadow: 0 0 0 !important;
}

.modal-body .successPage .userContainer {
  padding: 0;
  margin: 0;
}

#bookingSuccess .modal-body .successPage {
  padding: 0;
}

#bookingSuccess .modal-body .successPage .successCard {
  border-radius: 0;
  padding-top: 20px;
}

#bookingSuccess .modal-body .successPage .userContainer {
  padding: 0;
  margin: 0;
}

#bookingSummary .modal-body {
  max-height: 480px;
  overflow-y: auto;
}

#bookingSummary .card {
  border: 0;
  border-radius: 0;
}

#addAddress .modal-body .selctAddressItem:nth-child(2) {
  max-height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
}

.userContainer .userContainer .card {
  border: 0;
  box-shadow: inherit;
}

.userContainer .card .py-5 {
  padding-top: 0.8rem !important;
}

.creditCardDiv {
  display: flex;
  justify-content: space-between;
  background-color: #f1f1f1;
  padding: 40px;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  flex-wrap: wrap;
}

#cardNumber {
  height: 50px !important;
}

#expiryDate {
  height: 50px !important;
}

#cvv {
  height: 50px !important;
}

.card-number-frame.frame--activated {
  width: 100% !important;
  background: #fff;
}

.frame--activated {
  width: 50% !important;
  border: 1px solid #e1e1e1;
  padding-left: 12px;
  background: #fff;
}

/* .cvv-frame.frame--activated{ border-right: 0 !important;}     */

/*==========Language============*/

ul {
  padding: 0;
}

.translated-rtl body {
  direction: rtl;
}

.translated-rtl .bellIcon span {
  margin-right: 10px;
  margin-left: 0;
}

.translated-rtl .navbar-light .navbar-brand {
  margin-right: 0;
}

.translated-rtl .loginbtn img {
  margin-left: 0;
  margin-right: 10px;
}

.translated-rtl .navbar .ms-auto {
  margin-left: 0 !important;
}

.translated-rtl .navbar .ms-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}

.translated-rtl .navbar .me-3 {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}

.translated-rtl .slider {
  direction: ltr;
}

.translated-rtl .owl-carousel {
  direction: ltr;
}

.translated-rtl .extraCard {
  direction: ltr;
}

.translated-rtl .extraCard .extraContent {
  direction: rtl;
}

.translated-rtl .manyReasonsec .me-4 {
  margin-right: 0 !important;
  margin-left: 1.5rem !important;
}

.translated-rtl .testimonialDiv {
  direction: rtl;
}

.translated-rtl .testimonilaImage img {
  border-radius: 0 8px 8px 0;
}

.translated-rtl .rightHandsApp .appImage {
  text-align: left;
}

.translated-rtl .iconbtn i {
  margin-right: 0;
  margin-left: 10px;
}

.translated-rtl .footerLogo {
  margin-left: 70px;
  margin-right: 0;
}

.translated-rtl .footerLinks {
  margin-left: 50px;
  margin-right: 0;
}

.translated-rtl .modal-header .btn-close {
  margin-left: 0;
}

.translated-rtl .creditCard .list-group {
  direction: ltr;
}

.translated-rtl .list li i {
  transform: rotate(180deg);
}

/* .translated-rtl  #profileForm .inputdiv:first-child{ direction: ltr;} */
.translated-rtl .form-select {
  background-position: 3% !important;
}

.translated-rtl .checkboxItem .form-check input {
  margin-right: 0;
  margin-left: 20px;
}

.translated-rtl #resechduleModal .btn-close {
  left: 10px;
  right: auto;
}

.translated-rtl #resechduleModal span {
  margin-left: 15px;
  margin-right: 0;
}

.translated-rtl .rightHandsApp .heading {
  text-align: right;
}

.translated-rtl .phonecodeselect {
  /* direction: ltr; */
}

.translated-rtl .react-tel-input .country-list .country {
  direction: rtl;
  text-align: right;
}

.translated-rtl .react-tel-input .country-list .search-box {
  text-align: right;
}

.translated-rtl .stepsCard .heading {
  text-align: right;
}

.translated-rtl .creditfirst .form-check h3 img {
  margin-left: 20px;
  margin-right: 0;
}

.translated-rtl .cardsec h3 img {
  margin-left: 0;
  margin-right: 5px;
}

.translated-rtl .isDone .helper-text-item:after {
  left: auto;
  right: -10px;
}

.translated-rtl .faqPage.faqsec .accordion-button {
  padding: 1.5rem 4.25rem 1.5rem 2.25rem;
}

.translated-rtl .faqsec .accordion-button::after {
  right: 30px;
}

.translated-rtl .blogpageBanner .blogbnrContent {
  padding-left: 0;
  padding-right: 35px;
  border-radius: 8px 0 0 8px;
  border-left: 1px solid #cdcdcd;
  border-right: 0;
}

.translated-rtl .blogpageBanner .blogImage {
  border-radius: 0 8px 8px 0;
}

.translated-rtl .pac-item {
  direction: rtl;
  text-align: right;
}

.translated-rtl .creditExpiry h3 img {
  margin-right: 0;
  margin-left: 15px;
}

.translated-rtl .recentTranstion > h3 {
  padding-left: 32px;
  padding-right: 32px;
}

.translated-rtl .faqsec .accordion-button {
  padding: 1.2rem 4.25rem 1.2rem 2.25rem;
}

.translated-rtl .faqsec h3 {
  padding-right: 30px;
  padding-left: 0;
}

.translated-rtl .homeCleanBanner .bannerContent {
  padding-left: 0px;
  padding-right: 80px;
}

.translated-rtl #covidModal .form-check input {
  margin-left: 10px;
}

.translated-rtl .selctAddressItem h3 i {
  margin-left: 10px;
}

.translated-rtl .publishlogo {
  margin-left: 15px;
  margin-right: 0;
}

.translated-rtl .blogDetail .socailIcon span:last-child {
  margin-right: 10px;
}

.translated-rtl .stepService .heading img {
  margin-left: 0;
  margin-right: 5px;
}

.translated-rtl .priceTotal h3 span {
  margin-right: 0.5rem !important;
  margin-left: 0 !important;
}

.translated-rtl .checkboxItem .form-check label span {
  margin-right: 10px;
  margin-left: 0px;
}

.translated-rtl .checkboxItem .form-check label {
  width: 90%;
}

.translated-rtl .checkboxItem .form-check label i {
  margin-right: 0;
  margin-left: 5px;
}

.translated-rtl .complementsec input {
  padding-left: 0px;
  padding-right: 100px;
  background-position: 99%;
}

.translated-rtl .btn-fav span {
  margin-right: 0;
  margin-left: 15px;
}

.translated-rtl .addVoucher i {
  transform: rotate(180deg);
}

.translated-rtl .appointmentService p i {
  margin-left: 10px;
}

.translated-rtl .recentTranstion > h3 span {
  padding-left: 0;
  padding-right: 15px;
}

.translated-rtl .checkboxItem p {
  padding-right: 50px;
  padding-left: 0;
}

.translated-rtl .serviceCategoryBtn {
  display: block;
  font-size: 13px;
  padding: 9px 10px;
}

.translated-rtl .button-with-counter span {
  margin-left: 12px;
}

.translated-rtl .Payment .checkboxItem .form-check input {
  float: right;
}

.translated-rtl .Payment .checkboxItem .form-check div:nth-child(1) {
  min-width: 100%;
}

.translated-rtl .checkboxItem .form-check .switch {
  width: 60px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.translated-rtl .checkboxItem .form-check .switch .switchslide {
  margin-right: 0;
}

.translated-rtl .checkboxItem .switchBtn img {
  margin-left: 20px;
  margin-right: 0;
}

.translated-rtl .Payment .checkboxItem .form-check input {
  float: right;
}

.translated-rtl .Payment .checkboxItem .form-check div:nth-child(1) {
  min-width: 100%;
}

.translated-rtl .checkboxItem .form-check .switch {
  width: 60px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.translated-rtl .checkboxItem .form-check .switch .switchslide {
  margin-right: 0;
}

.translated-rtl .checkboxItem .switchBtn img {
  margin-left: 20px;
  margin-right: 0;
}

.translated-rtl .actionButton .btn-back img {
  transform: rotate(180deg);
}

.translated-rtl .actionButton .btn-back img {
  transform: rotate(180deg);
}

.translated-rtl .checkboxItem .form-check input:checked + .switchslide:before {
  left: 4px;
}

/*==========Language============*/

/*===========20Sep===============*/

.bannerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bannerwelcome h2 {
  font-size: 40px;
  text-align: right;
  color: #432375;
  font-weight: normal;
}

.serviceSlider {
  position: relative;
}

.image-container .item {
  display: inline-block;
  margin-right: 20px;
  width: 170px;
}

.serviceBanner .owl-stage-outer ::-webkit-scrollbar {
  -webkit-appearance: none;
}

.serviceBanner .owl-stage-outer ::-webkit-scrollbar:vertical {
  width: 8px;
}

.serviceBanner .owl-stage-outer ::-webkit-scrollbar:horizontal {
  height: 8px;
}

.image-container::-webkit-scrollbar-thumb {
  background-color: #a805ffad;
  border-radius: 10px;
  border: 0px solid #f2e9ff;
}

.image-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f2e9ff;
}

/* .serviceBanner .owl-stage-outer{    overflow-x: auto !important;  overflow-y: hidden !important;} */
.scrollslider {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.serviceSlider {
  width: 100%;
  font-size: 25px;
  white-space: normal;
  margin: 0px 25px 0 0;
  display: inline-block;
  line-height: 21px;
}

.serviceSlider:last-child {
  margin-right: 0;
}

.image-container {
  vertical-align: middle;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 20px;
}

.prev,
.next {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
  margin: 0 auto;
  cursor: pointer;
  color: black;
  line-height: 40px;
  transition: 0.6s ease;
  font-size: 35px;
  font-weight: bold;
}

.next i {
  font-size: 35px;
  line-height: 40px;
  font-weight: bold;
}

.prev i {
  font-size: 35px;
  line-height: 40px;
  font-weight: bold;
}

.wrapper {
  position: relative;
}

.swiper {
  padding-bottom: 1px !important;
  border-radius: 8px;
}

.serviceBanner .swiper-scrollbar {
  background: rgb(156 39 176 / 11%) !important;
}

.serviceBanner .swiper-scrollbar-drag {
  background: #9c27b0 !important;
}

.swiper:after {
  position: absolute;
  top: 0;
  right: -30px;
  /* width: 55px; */
  height: 230px;
  width: 30px;
  content: "";
  transform: rotateY(180deg);
  /* right: 0px; */
  z-index: 111;
  box-shadow: 19px 4px 26px #13131399;
  /* background: url(https://dvdljkkxpxqo3.cloudfront.net/images/border.png.webp); */
}

.swiper:before {
  position: absolute;
  top: 0;
  left: -30px;
  width: 30px;
  height: 215px;
  content: "";
  /* box-shadow: 19px 4px 26px #13131399; */
  box-shadow: 19px 4px 26px #13131387;
  z-index: 111;
}

.serviceSlider h3 {
  font-size: 16px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
}

.swiper-pagination {
  display: none !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 26px !important;
}

.serviceBanner .swiper-button-prev {
  font-size: 35px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  left: 5px;
  border-radius: 50%;
  box-shadow: 1px 2px 3px #00000026;
  cursor: pointer;
  z-index: 222;
  color: #8e7daa;
  border: none;
  outline: none;
  background: #fff;
  align-items: center;
}

.serviceBanner .swiper-button-next {
  font-size: 35px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  right: 5px;
  border-radius: 50%;
  box-shadow: 1px 2px 3px #00000026;
  cursor: pointer;
  z-index: 222;
  color: #8e7daa;
  border: none;
  outline: none;
  background: #fff;
}

.owl-carousel .extraCard img {
  width: auto !important;
}

.loginButton .btnLarge {
  font-size: 16px;
  padding: 10px 20px;
}

.screenProfessional {
  background: #f4f4f4;
}

.happynesssArea {
  background-color: rgb(158, 109, 200);
  background-image: url(../public/images/happiness-gaurantee.webp);
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  height: 545px;
  color: #fff;
}

.happynesssArea h2 {
  color: #fff;
}

.happynesssArea p {
  color: #fff;
}

.happyContent {
  padding: 45px 60px 0 0;
}

.happyContent img {
  width: 150px;
}

.happyContent p a {
  color: #fff;
  text-decoration: underline !important;
}

.nowCheckArea {
  padding: 40px 0;
  background-color: #3d1f6c;
}

.nowcheckContent {
  max-width: 760px;
  margin: 0 auto;
}

.nowCheckArea h2 {
  color: #fff;
  margin-bottom: 16px;
}

.nowCheckArea p {
  color: #fff;
}

.fullSlider .container {
  position: relative;
}

.fullSlider .owl-carousel .container {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
}

.fullSlider .item {
  position: relative;
}

.fullSlider .item img {
  height: 310px;
  object-fit: cover;
}

.fullSlider .item::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(to right, #673ab7a6, #f3733500);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #673ab7a6, #f3733500);
  top: 0;
}

.fullSlider .item .sliderContent {
  color: #fff;
  max-width: 520px;
  z-index: 222;
}

.fullSlider .item .sliderContent h2 {
  color: #fff;
  margin-bottom: 16px;
  font-size: 32px;
}

.fullSlider .item .sliderContent h3 {
  color: #fff;
  font-size: 18px;
  line-height: 28px;
}

.loginButton {
  position: absolute;
  top: -140px;
  right: 0;
  z-index: 1;
  background-color: #ffffffc7;
  padding: 20px;
  border-radius: 10px;
}

.loginButton .form-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginButton .btn {
  min-width: 140px;
  margin: 5px;
  border-radius: 30px;
}

.loginButton .btn-secondary {
  background-color: #fff;
  color: #000000;
}

.loginButton .btn-secondary:hover {
  background-color: #ddbb03;
  color: #000000;
}

.aboutusArea {
  background-color: #dedede75;
}

.aboutusArea .row {
  align-items: center;
}

.aboutusArea .heading {
  text-align: left;
}

.aboutusArea .heading h2 {
  color: #3d1f6c;
}

.aboutusArea .heading p {
  color: #3d1f6c;
  font-size: 18px;
  line-height: 1.8;
}

/*steps*/
.cleanerHour {
  border: 1px solid #cfcfcf;
  border-radius: 0.25rem;
}

.cleanerHour select {
  padding: 6px 6px;
  width: 50%;
  outline: 0;
  font-size: 14px;
  border-radius: 0 3px 3px 0;
  min-height: 45px;
  border: 0;
}

.cleanerHour select:nth-child(1) {
  border-right: 1px solid #cfcfcf;
  border-radius: 3px 0 0 3px;
}

.paymentLabel input {
  width: 20px;
  height: 20px;
}

.cleanerHour input {
  padding: 6px 12px;
  outline: 0;
  font-size: 14px;
  border-radius: 0 3px 3px 0;
  min-height: 45px;
  border: 0;
}

.cancelPolicy p {
  color: #432375;
  text-align: right;
  padding-right: 40px;
}

.AddressStep + .servicePackage .col-lg-7 {
  width: 100%;
}

.AddressStep + div + .servicePackage .col-lg-7 {
  width: 100%;
}

/*AboutPage*/
/* .aboutProblem{background: url(https://dvdljkkxpxqo3.cloudfront.net/images/whybg.jpg.webp) no-repeat;  } */
.problemsArea .heading {
  text-align: left;
  padding-right: 64px;
  padding-top: 30px;
}

.problemsArea .heading h2 {
  margin-bottom: 8px;
}

.problemsArea .heading p {
  padding-top: 0;
}

.whorighthands {
  background: linear-gradient(
    90deg,
    rgba(61, 28, 111, 0.95) 0%,
    rgba(108, 39, 173, 0.95) 100%
  );
}

.whorighthands .heading {
  text-align: left;
  color: #fff;
}

.whorighthands img {
  border-radius: 0 150px 0 0;
}

.whorighthands .heading h2 {
  margin-bottom: 8px;
  color: #fff;
}

.whorighthands .heading h5 {
  line-height: 25px;
  color: #fff;
  padding-bottom: 0;
}

.whorighthands p {
  color: #fff;
}

.whorighthands ul {
  padding: 0 0 0 16px;
}

.whorighthands ul li {
  margin-bottom: 8px;
  text-align: left;
  color: #fff;
}

.ourProcess {
  background-color: #f7eeff;
}

.ourProcess .row > div {
  display: flex;
}

.aboutProblem {
  background: url(https://dvdljkkxpxqo3.cloudfront.net/images/bge.png.webp)
    no-repeat;
  background-position: 100%;
}

.easyBook .heading p {
  padding: 0 200px;
  line-height: 30px;
}

.aboutPage .heading h2 {
  margin-bottom: 10px;
}

.aboutPage .problemsArea img {
  border-radius: 0 150px 0 0;
}

.aboutPage .nowcheckContent {
  max-width: 880px;
}

.aboutForm {
  background-color: #e9c0ff61;
}

.aboutForm .heading h2 {
  font-weight: 900;
  color: #3d1c6f;
}

.aboutForm .heading p {
  font-weight: 400;
}

.formSec {
  padding: 0 200px;
}

.aboutForm label {
  line-height: 22px;
  display: flex;
  align-items: center;
}

.aboutForm .form-control {
  box-shadow: 0px 4px 1px #a805fffa;
  border: 1px solid #8604cca3;
}

.aboutForm input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

.serviceDiv {
  background: #fff;
  width: 100%;
  padding: 40px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 2px 4px #4323753d;
  margin-bottom: 24px;
}

.serviceDiv img {
  border-radius: 8px;
  width: 30%;
  height: 72px;
  margin: 0 auto 24px;
}

.serviceDiv h3 {
  margin-bottom: 16px;
}

.serviceDiv p {
  margin-bottom: 0;
}

/*guarantee*/

.guarnteePage h2 {
  margin-bottom: 10px;
}

.guarnteeBanner {
  background: url(https://dvdljkkxpxqo3.cloudfront.net/images/guarntee.jpg.webp)
    no-repeat;
  height: 465px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100%;
}

.guarnteeBannerContent {
  box-shadow: 5px 10px #ddbb03;
  background-color: #ffffffba;
  padding: 60px;
  max-width: 520px;
  text-align: center;
  border-radius: 0px 130px 0 130px;
}

/* .guarnteeBannerContent h2{ font-size: 45px; line-height: 1.1; margin-bottom: 40px; color: #ddbb03;}
.guarnteeBannerContent h3{ font-size: 20px; color: #ddbb03;} */

.experienceMatters {
  background: url(https://dvdljkkxpxqo3.cloudfront.net/images/bge2.png.webp)
    no-repeat;
  height: 400px;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.experienceMattersContent {
  max-width: 480px;
  margin-left: 180px;
}

.guarnteePage h3 {
  line-height: 1.6;
}

.professionalsArea {
  background-color: #f6f6f6;
}

.professionalsArea .row {
  align-items: center;
}

.professionalsReview {
  display: flex;
  justify-content: space-between;
}

.professionalbox:nth-child(1) {
  margin-left: 0;
}

.professionalbox {
  text-align: center;
  background-color: white;
  width: 50%;
  height: auto;
  border-radius: 5px;
  padding: 1.5em;
  margin: 0 1.5em;
}

.professionalbox img {
  width: 78px;
  height: 78px;
  margin-bottom: 1em;
  border-radius: 50%;
}

.professionalbox h4 {
  font-weight: 500;
  font-size: 17px;
  padding: 10px 0;
}

.professionalbox .starRating {
  padding: 20px 0;
  border-bottom: 2px solid #ebebeb;
}

.professionalbox .starRating i {
  color: #ffb600;
  margin: 0 3px;
  font-size: 24px;
}

.professionalbox .starRating span {
  font-size: 18px;
  opacity: 0.6;
  font-weight: 600;
}

.professionalsContent h3 span {
  color: #3d1c6f;
}

.readyBook {
  background-color: #fff;
}

.btn-outline {
  border-color: #ddbb03 !important;
  color: #ddbb03 !important;
  background-color: #fff !important;
}

.readyBook .btn {
  min-width: 223px;
}

/*Membership*/
.memberShippage h2 {
  margin-bottom: 10px;
}

.memberShipBanner .container {
  position: relative;
}

.memberShipBanner .memberBnrContent {
  position: absolute;
  top: 100px;
  width: 100%;
}

.memberShipBanner img {
  width: 100%;
  min-height: 506px;
}

.memberBnrContent {
  position: relative;
  z-index: 11;
  top: 100px;
}

.benefitsArea {
  background: #ffcafa17;
}

.benefitsArea .row {
  max-width: 910px;
  margin: 0 auto;
}

.benefitContent {
  text-align: center;
  padding: 0 25px 40px;
}

.benefitContent img {
  margin: 0 auto 10px;
  height: 50px;
}

.benefitContent p {
  font-weight: 400;
  margin: 0;
}

.memberShippage .serviceDiv {
  box-shadow: 0px 9px 21px #b9b9b93d;
  margin: 20px 10px;
}

.extraCard {
  min-height: 206px;
  max-height: 206px;
}

/* .offerCard .extraContent h3{ word-break: break-all;} */
/* .clubCard .extraContent h3{ margin-bottom: 80px;} */
/* .offerCard img{ margin: 15px ; max-width: 150px;} */

.addmoreService {
  margin-bottom: 40px;
}

.addmoreService h3 {
  color: #3d1c6f;
}

.buttonadd .hide {
  display: none;
}

.profileForm {
  height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
}

#ProfileSetup .modal-header {
  position: absolute;
  right: -10px;
  z-index: 999;
  top: -10px;
}

.paymentLabel label {
  line-height: 26px;
}

.circleInput {
  background-color: #fcfaff;
  padding: 20px;
}

.circleInput h4 {
  text-align: center;
  font-size: 18px;
  padding-bottom: 10px;
  opacity: 0.7;
}

.bellIcon span {
  font-size: 25px;
  margin-left: 10px;
  color: #432375;
}

.mapToogleDiv h3 {
  font-size: 16px;
  margin-bottom: 16px;
}

/*SwitchButton*/
.switch {
  position: relative;
  display: inline-block;
  width: 50px !important;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switchslide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2e9ff !important;
  transition: 0.4s;
  margin-left: 0 !important;
  border-radius: 35px !important;
}

.switch input:checked + .switchslide:before {
  left: -5px;
}

.switch .switchslide:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .switchslide {
  background-color: #432375 !important;
}

input:checked + .switchslide:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switchslide.round {
  border-radius: 34px;
}

.switchslide.round:before {
  border-radius: 50%;
}

.Payment .checkboxItem .switchBtn {
  padding-left: 0;
}

.translated-rtl .checkboxItem .switchBtn .form-check label {
  width: auto;
}

.switchFlex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.switchFlex label img {
  margin-right: 20px;
}

.forgotPassword {
  max-width: 440px;
}

.payBYcredit .form-check {
  margin-bottom: 24px;
  align-items: flex-start;
}

.Payment .payBYcredit p {
  color: #707070;
  padding-left: 0;
}

.creditHeading {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.creditHeading .btn {
  padding: 10px 20px;
  font-size: 14px;
}

.creditHeading .btn i {
  font-size: 18px;
  position: relative;
  top: 1px;
}

.rhClubMember .form-check label {
  margin-bottom: 1px;
}

.rhClubMember .form-check p {
  margin-bottom: 1px;
}

.rhClubMember .personalForm {
  justify-content: flex-start;
}

.translated-rtl .fullSlider .item .sliderContent {
  right: 40px;
  left: auto;
  text-align: right;
}

.translated-rtl .fullSlider .loginButton {
  left: 0;
  right: auto;
}

.translated-rtl .happynesssArea {
  background-position: left;
}

.translated-rtl .happyContent {
  padding: 45px 0px 0 60px;
}

.translated-rtl .problemsArea .heading {
  text-align: right;
  padding-left: 64px;
  padding-right: 0;
}

add-card {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* label {
    display: block;
    margin-bottom: 5px;
  } */

input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.credit-card-input input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.credit-card-input input::placeholder {
  color: #6c757d;
}

.credit-card-input input:focus {
  border-color: #4d90fe;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/*===========20Sep===============*/

/*01-02-2024*/
#showInvoice .modal-body {
  height: 80vh;
  overflow-y: auto;
}

.appointmentDetails .buttonSec h4 a {
  background: none;
}

.addPhotosSec button.ant-btn.ant-btn-default {
  background: none;
  border: 0;
}

.errorPage {
  display: flex;
  height: 100vh;
  width: 100%;
  background: #a200f908;
  justify-content: center;
  align-items: center;
}

.pageNotFound {
  display: flex;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  background: #a908ff;
  border-radius: 20px;
  padding: 32px;
}

.pageNotFound img {
  max-width: 270px;
}

.errorImage {
  padding-right: 40px;
}

.errorText ul {
  padding-left: 20px;
  margin-bottom: 22px;
}

.errorText ul li {
  color: #fff;
  line-height: 23px;
  margin-bottom: 8px;
}

.errorText h2 {
  color: #fff;
}

.card.newSerachBox {
  display: block;
}

.card.newSerachBox .heading {
  width: 100%;
}

.newSerachBox .searchPlace {
  padding: 0 16px;
}

.newSerachBox .searchPlace {
  top: 60px;
}

.newSerachBox .searchPlace input {
  max-width: 100%;
}

.addresFormHeight {
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  border: 1px solid #cdcdcd;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 222;
}

.card.newSerachBox .stepsCard {
  width: 100%;
}

#changeCountry label input {
  border: 0;
}

.addresFormHeight .loginMobileInput {
  margin-top: 0;
}

.serviceBanner .serviceSlider span img {
  max-height: 80px;
}

@media (min-width: 1920px) {
  .SliderbgImage {
    height: 450px;
    background-position: 0 0;
  }
}

@media (min-width: 2300px) {
  .SliderbgImage {
    height: 500px;
  }
}

@media (min-width: 2700px) {
  .SliderbgImage {
    height: 600px;
  }
}

@media (min-width: 1200px) {
  .blogHeader .container {
    max-width: 1030px;
  }

  .blogPAGE .container {
    max-width: 1030px;
  }

  .blogFooter .container {
    max-width: 1030px;
  }

  .blogSec .col-lg-4 {
    display: flex;
  }

  /* .extraCard .extraContent{ text-align: right;} */
}

/* .divsticky .owl-stage{    display: flex;
justify-content: center;  white-space: nowrap;} */
@media (max-width: 1300px) {
  /*Arabic*/
  .translated-rtl .navbar .dropdown-menu {
    right: auto !important;
    left: 0 !important;
  }
}

@media (max-width: 1320px) {
  .servicedetail img {
    height: 180px;
  }

  /* .servicepopopList{ height:195px; } */

  .happynesssArea {
    background-position: 450px;
  }

  .memberShipBanner img {
    min-height: 506px;
  }

  .item-container .divider {
    margin-left: -23px;
    margin-right: -23px;
  }

  .navbar .dropdown-menu {
    height: 480px;
  }
}

@media (max-width: 1280px) {
}

@media (max-width: 1240px) {
  .servicedetail img {
    height: 180px;
  }

  /* .servicepopopList{ height:195px; } */
  .happynesssArea {
    background-position: 400px;
  }

  .fullSlider .item img {
    height: 270px;
  }
}

@media (max-width: 1220px) {
  .footerLogo {
    margin-right: 10px;
    margin-left: 15px;
  }

  .footerLinks {
    margin-left: 10%;
    font-weight: 400px;
  }

  .testimonialContent {
    padding: 20px;
  }

  .testimonialContent img {
    width: 25px !important;
    margin-bottom: 10px;
  }

  .testimonilaImage img {
    width: 206px !important;
    height: 270px;
    object-fit: cover;
  }

  .serviceSlider {
    padding: 20px;
  }

  .emergencyCard .btn {
    margin-top: 0;
  }

  .item-container .divider {
    width: 150px;
  }

  .ServiceStpes .item-container .divider {
    width: 310px;
  }

  .step-wizard {
    justify-content: center;
  }

  .disinTabLink {
    overflow-x: auto;
    white-space: nowrap;
  }

  .extraCard .extraContent {
    padding-right: 20px;
    padding-left: 10px;
  }

  .socialIcon {
    justify-content: center;
    margin-top: 10px;
    width: 100%;
  }

  .socialIcon div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .translated-rtl .checkboxItem .form-check label {
    width: auto;
  }

  .translated-rtl .footerserviceLinks {
    width: 0%;
  }

  .translated-rtl .footerLinks {
    margin-left: 20px;
  }

  .memberShipBanner img {
    min-height: 377px;
  }

  .fullSlider .item .sliderContent {
    top: 30px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1120px) {
  .serviceCategoryBtn {
    width: 100%;
    padding: 6px 8px;
    font-size: 14px;
  }

  .item-container .divider {
    width: 120px;
  }

  .professionalSec .serviceSlider span {
    height: 80px;
  }
}

@media (max-width: 1024px) {
  .extraCard .extraContent h3 {
    font-size: 17px;
  }

  .extraCard .extraContent {
    margin-top: 12px;
  }

  .serviceSlider {
    padding: 12px;
  }

  .extraCard .extraContent {
    width: 60%;
  }

  .item-container .divider {
    width: 120px;
  }

  .item-container .item.is-active {
    width: 120px;
  }

  .ServiceStpes .item-container .divider {
    width: 310px;
  }

  .step-wizard {
    justify-content: center;
  }

  .step-wizard .step-title {
    width: 200px !important;
  }

  .socialIcon {
    justify-content: center;
    margin-top: 10px;
    width: 100%;
  }

  .socialIcon div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .servicepopopList {
    height: auto;
  }

  .translated-rtl .footerLogo {
    margin-left: 20px;
  }

  .iconbtn {
    margin: 5px;
  }

  .happynesssArea {
    background-position: 400px;
  }

  .happyContent {
    padding: 20px 0 0 0;
  }

  .professionalbox {
    margin: 0 8px;
    height: auto;
  }

  .memberShipBanner img {
    min-height: 365px;
  }

  .memberShipBanner .memberBnrContent {
    width: 35%;
    top: 20px;
  }

  .guarnteeBanner {
    background-size: cover;
  }

  .professionalSec .serviceSlider span {
    height: 110px;
  }

  .extraCard .justify-content-between {
    justify-content: flex-start !important;
  }

  .offerCard .extraContent h3 {
    word-break: auto-phrase;
  }
}

@media (max-width: 991px) {
  .bannerSection {
    position: relative;
  }

  .bannerSection::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff86;
  }

  .bannerSection .container {
    z-index: 5;
  }

  .homeCleanBanner img {
    height: 350px;
  }

  .homeCleanBanner .bannerContent {
    padding: 80px 40px 40px 40px;
  }

  .serviceSlider {
    padding: 10px;
    min-height: 110px;
  }

  .bannerwelcome h2 {
    font-size: 24px;
  }

  /* .extraCard{ margin-bottom: 20px;} */
  .offerCard img {
    max-width: 140px;
  }

  .extraCard {
    max-height: 218px;
  }

  .blogSec .col-lg-4 {
    display: flex;
  }

  .blogContent {
    padding: 15px;
  }

  .manyReasonsec .container {
    max-width: 720px;
  }

  .manyReasonsec {
    padding: 30px 0;
  }

  .footerLogo {
    margin: 0 auto 30px;
    width: 100%;
    justify-content: center;
  }

  .footerLinks {
    margin-right: 0;
  }

  .iconbtn {
    padding: 7px 14px;
    font-size: 13px;
  }

  .footer .container {
    max-width: 95%;
  }

  .footerLogo img {
    margin-top: 0;
  }

  .testimonilaImage img {
    width: 160px;
    height: 225px;
  }

  .userContainer {
    margin-bottom: 20px;
  }

  .footer .justify-content-between {
    justify-content: flex-start !important;
  }

  .footerLinks.footerserviceLinks {
    width: 100%;
  }

  .footerLinks {
    width: 33.3%;
  }

  /* .footerserviceLinks ul{ } */
  .footerserviceLinks li {
    width: 33.3%;
  }

  .socialIcon {
    margin-top: 10px;
    width: auto;
  }

  .socialIcon div {
    display: block;
  }

  .iconbtn {
    margin: 5px;
  }

  /*Steps*/
  .stepsFromPage .pt-5 {
    padding-top: 0px !important;
  }

  .actionButton {
    position: fixed !important;
    top: auto;
    margin-bottom: 0;
    z-index: 111;
  }

  .item-container .divider {
    width: 100px;
    margin-top: -10px;
  }

  .details .step-title {
    display: none;
  }

  .item-container .item {
    width: 80px;
  }

  .ServiceStpes .item-container .divider {
    margin-top: -10px;
  }

  .stepsCard {
    min-height: auto;
  }

  .stepsContent .cardDetails {
    display: none !important;
  }

  .stepsFromPage .footer {
    display: none;
  }

  .stepsFromPage .card {
    margin-bottom: 130px;
    min-height: auto !important;
  }

  .stepsFromPage .py-5 {
    padding-bottom: 30px !important;
  }

  .actionButton {
    padding: 20px 30px;
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #cdcdcd;
    left: 0;
    right: 0;
  }

  /* .navbar{ position: fixed; width: 100%;} */
  .priceTotal {
    text-align: center;
  }

  .priceTotal p {
    margin-bottom: 0;
  }

  .priceTotal h3 {
    font-weight: 700;
  }

  .actionButton .btn {
    min-width: 110px;
  }

  .btn-back:disabled {
    display: none;
  }

  .location {
    margin-top: 20px;
  }

  #bookingSummary .card {
    border: 0;
    margin-bottom: 0;
  }

  .stepService .buttonItem label {
    margin-right: 10px !important;
  }

  .stepsCard .buttonItem {
    margin-right: 0;
  }

  .cardsec h3 {
    font-size: 14px;
  }

  .priceTotal {
    display: block;
  }

  .item-container .item.is-active {
    width: 80px;
  }

  .servicePackage .actionButton {
    background: #fff !important;
    z-index: 222;
    border-radius: 0;
    margin: 0;
    top: auto !important;
    border: 1px solid #cdcdcd;
    padding-bottom: 15px;
  }

  .ServiceStpes .item-container .divider {
    width: 240px;
  }

  .faqtab .tabBtn {
    margin-right: 10px;
  }

  .blogbnrContent {
    padding-left: 15px;
  }

  .blogHeader .navbar .navbar-brand {
    margin: 0 auto;
    max-width: 100%;
  }

  #addressform select.form-select.pl-0.genderSelect {
    margin-bottom: 10px;
  }

  .professionalbox {
    margin: 16px !important;
  }

  .professionalsContent {
    margin: 16px;
  }

  .experienceMattersContent {
    max-width: 480px;
    margin-left: 0;
    background: #ffffffcf;
    padding: 20px;
    border-radius: 0 100px 0 0;
  }

  .addmoreService {
    margin-bottom: 24px;
  }

  /*Arabic*/
  .translated-rtl .footerLinks {
    margin-left: 0;
    margin-right: 0;
  }

  .translated-rtl .footerLogo {
    margin-left: 10px;
  }

  .translated-rtl .footerLinks {
    margin-left: 0px;
  }

  .translated-rtl .homeCleanBanner .bannerContent {
    padding: 80px 40px 40px 40px;
  }

  .mapContent .inputdiv {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
  }

  .mapContent .row.mt-4 {
    margin-top: 0 !important;
  }

  #bookingSummary .modal-body {
    padding: 0 !important;
  }

  #bookingSummary .card {
    height: 470px;
    overflow-y: auto;
  }

  .aboutusArea img {
    margin-top: 24px;
  }

  .fullSlider .item .sliderContent {
    top: 30px;
  }

  .professionalSec .serviceSlider span {
    height: 140px;
  }

  .card.newSerachBox {
    margin-bottom: 0 !important;
  }

  .addresFormHeight {
    margin: 40px auto 120px;
    z-index: 11;
  }

  .addresFormHeight .login_country_code#country_code {
    margin-left: 0 !important;
    padding-left: 50px;
    text-align: left;
  }

  .addresFormHeight .phonecodeselect .react-tel-input {
    margin-bottom: 8px;
  }

  .react-tel-input .flag-dropdown {
    z-index: 222;
  }

  .addresFormHeight .inputdiv .form-control {
    padding-left: 16px;
  }

  .addresFormHeight .inputdiv #mobile {
    padding-left: 24px;
  }

  iframe#launcher {
    bottom: 105px !important;
  }
}

@media (max-width: 767px) {
  .pt-7 {
    padding-top: 30px;
  }

  .py-7 {
    padding: 30px 0;
  }

  .heading {
    margin-bottom: 20px;
  }

  .lifeEasySec .heading {
    margin-bottom: 40px;
  }

  .aboutusArea img {
    margin-top: 0;
  }

  .navbar .roundedCircle {
    order: 1;
    padding: 0 !important;
    margin-right: 10px !important;
  }

  .navbar .loginbtn {
    order: 4;
    padding: 6px 10px;
    margin-right: 0 !important;
  }

  .navbar .navbar-brand {
    order: 3;
    max-width: 90px;
  }

  .navbar .language {
    order: 2;
    margin-left: 0.6rem !important;
  }

  .nav-items {
    color: red !important;
  }

  .bannerSection {
    text-align: center;
    min-height: auto;
  }

  .bannerSection .bannerContent {
    max-width: 100%;
  }

  .navbar .align-items-center:not(.roundedCircle) {
    justify-content: space-between;
    width: 100%;
  }

  .homeCleanBanner img {
    height: 280px;
  }

  .homeCleanBanner .bannerContent {
    padding: 30px;
  }

  .blogDiv {
    margin-bottom: 20px;
  }

  .manyReasonsec .container .heading {
    margin-bottom: 20px;
  }

  .reasonContent p {
    margin-bottom: 25px;
    padding-right: 0;
  }

  .testimonialDiv {
    flex-wrap: wrap;
  }

  .testimonilaImage {
    width: 100%;
    padding: 20px;
  }

  .testimonilaImage img {
    width: 180px !important;
    height: 180px;
    border-radius: 50%;
    margin: 0 auto;
  }

  .testimonialContent {
    width: 100%;
    text-align: center;
    padding: 10px 20px 20px;
  }

  .testimonialContent img {
    margin: 0 auto 10px;
  }

  .rightHandsApp {
    padding: 60px 0 0 0;
  }

  .rightHandsApp .heading {
    text-align: center;
  }

  .burttonImage {
    text-align: center;
  }

  .rightHandsApp .mt-5 img {
    max-width: 150px;
  }

  .appImage {
    text-align: center;
  }

  .footerLogo {
    margin: 30px auto;
    width: 100%;
  }

  .footerLogo img {
    margin: 0 auto;
  }

  .footerLinks {
    width: 33.3%;
    margin-right: 0;
  }

  .socialIcon {
    width: auto;
    margin-top: 10px;
  }

  .iconbtn {
    margin: 0 0 5px 0;
  }

  .footer .justify-content-between {
    justify-content: flex-start !important;
  }

  .footerserviceLinks {
    width: 100%;
  }

  /* .footerserviceLinks ul{ } */
  .footerserviceLinks li {
    width: 100%;
  }

  .userContainer {
    padding: 0 15px;
  }

  .userInfo {
    padding: 20px 0;
  }

  .personalForm {
    padding: 20px;
  }

  .creditCard .personalForm {
    padding: 20px;
  }

  .helpQuote {
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 52px;
  }

  .py-5 {
    padding: 30px 0 40px 0 !important;
  }

  .navbar .dropdown-menu {
    min-width: 100%;
    overflow-y: auto;
    height: 300px;
  }

  .timeButtons .container {
    padding: 0;
  }

  .totalCredit h3 {
    font-size: 20px;
  }

  .totalCredit h2 {
    font-size: 40px;
    padding: 10px 0 10px 0;
  }

  .payGetMore h2 {
    font-size: 24px;
  }

  .payGetMore {
    padding: 30px;
  }

  .payGetMore .btn {
    min-width: 100%;
  }

  .transtionList {
    padding: 15px 15px;
  }

  .recentTranstion > h3 {
    padding-left: 15px;
  }

  .creditExpiry {
    padding: 25px 15px 25px 15px;
  }

  .transtionList > h3 {
    font-size: 16px;
    flex-wrap: wrap;
  }

  .transtionList > h3 span {
    font-size: 15px;
  }

  .packageSlider p {
    padding: 0 10px;
  }

  .packageSlider {
    margin: 20px 0;
  }

  .packageSlider hr {
    margin: 15px auto;
  }

  .packageSlider h6 {
    padding: 11px 0;
  }

  .getFreebox {
    padding: 20px;
  }

  .accountSettings ul li a {
    padding: 12px 20px;
  }

  .item-container .divider {
    width: 85px;
  }

  .stepsFromPage .py-5 {
    padding-bottom: 10px !important;
  }

  .stepsCard {
    padding: 15px 0 0 0;
  }

  .stepsCard .checkboxSec {
    padding: 0 15px;
  }

  .ServiceStpes .item-container .divider {
    width: 240px;
  }

  .hairService .container {
    padding: 0;
  }

  .hairService .slick-list {
    margin-left: 0;
  }

  .hairService .tabContent img {
    width: 100%;
  }

  .servicethumb {
    margin-top: 10px;
  }

  .faqtab {
    white-space: nowrap;
    overflow-x: auto;
  }

  .blogpageBanner {
    flex-wrap: wrap;
  }

  .blogpageBanner .blogImage {
    max-width: 100%;
  }

  .blogpageBanner .blogImage {
    height: 230px;
    border-radius: 8px 8px 0px 0px;
  }

  .blogpageBanner .blogbnrContent {
    width: 100%;
    min-height: auto;
    border-radius: 0 0px 8px 8px;
    border: 1px solid #cdcdcd;
    border-top: 0;
    border-left: 1px solid #cdcdcd;
    padding: 15px;
  }

  .blogpageBanner .blogbnrContent h3 {
    margin-bottom: 10px;
  }

  .blogPublished {
    margin-top: 10px;
  }

  .blogDetailImage img {
    min-height: auto;
  }

  .stepDate .timeButtons + p {
    margin-bottom: 35px;
  }

  .searchPlace {
    max-width: 95%;
    margin: 0 auto;
    top: 10px;
  }

  .searchPlace input.pac-target-input {
    font-size: 14px;
    padding-left: 40px !important;
  }

  .searchPlace i.fa.fa-search {
    left: 15px;
  }

  .searchPlace button {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .mapDiv + div {
    height: 250px !important;
  }

  .divMap > div > div {
    height: 400px !important;
  }

  .creditCard .card {
    min-height: auto;
  }

  .creditCard .personalForm {
    min-height: auto;
  }

  .owl-carousel .owl-nav .owl-next {
    right: -12px;
  }

  /* .owl-carousel .owl-stage-outer{ padding: 0 10px;} */
  .owl-carousel .owl-nav .owl-prev {
    left: -12px;
  }

  .testimonialSec .owl-carousel .owl-nav .owl-next {
    right: 0;
  }

  .testimonialSec .owl-carousel .owl-nav .owl-prev {
    left: 0;
  }

  .servicedetail .addBtn {
    padding: 8px 24px;
  }

  .professionalModal {
    height: 250px;
  }

  .reviewProgress {
    width: 60%;
  }

  #bookingSummary .card {
    height: 300px;
    overflow-y: auto;
  }

  .modal-open .modal.show {
    align-items: flex-start;
  }

  .servicedetail .modal-body .modal-body {
    max-height: 300px;
  }

  .userContainer .accountSettings .p-5 {
    padding: 1rem !important;
  }

  .userContainer .trackInviteArea {
    padding: 16px;
  }

  .userContainer .trackInvites {
    margin-bottom: 24px;
  }

  .userContainer .trackInvites:last-child {
    margin-bottom: 0;
  }

  .userContainer .trackInviteArea h2 {
    margin-bottom: 8px;
    font-size: 18px;
  }

  .stepsFromPage .pt-5 {
    padding-top: 15px !important;
  }

  /*Arabic*/
  .translated-rtl .navbar .me-3 {
    margin-right: 1rem !important;
    margin-left: 0rem !important;
  }

  .translated-rtl .footerLinks {
    margin-left: 0;
  }

  .translated-rtl .footerLogo {
    margin-left: 0;
  }

  .translated-rtl .serviceBanner {
    overflow: hidden;
  }

  .translated-rtl .testimonialSec {
    overflow: hidden;
  }

  .translated-rtl .rightHandsApp .appImage {
    text-align: center;
  }

  .translated-rtl .homeCleanBanner .bannerContent {
    padding: 30px;
  }

  .translated-rtl .recentTranstion > h3 {
    padding-right: 15px;
  }

  .bannerRow {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .bannerwelcome {
    width: 100%;
    padding-bottom: 20px;
  }

  .bannerwelcome h2 {
    text-align: center;
    font-size: 25px;
  }

  .happynesssArea {
    background-image: none;
  }

  .happyContent {
    padding-right: 0;
    text-align: center;
  }

  .screenProfessional h2 img {
    display: block;
    margin: 0 auto !important;
  }

  .screenProfessional {
    padding: 15px;
  }

  .nowcheckContent .align-items-center {
    text-align: center;
  }

  .problemsArea .heading {
    text-align: center;
    padding-right: 0;
    padding-top: 0;
  }

  .problemsArea .heading h2 {
    font-size: 25px;
    line-height: 30px;
  }

  .problemsArea {
    text-align: center;
  }

  .problemsArea .heading h2 br {
    display: none;
  }

  h3 {
    font-size: 18px !important;
  }

  h3 br {
    display: none;
  }

  .problemsArea .btn {
    margin-bottom: 30px;
  }

  .aboutusArea .px-5 {
    padding: 0 !important;
  }

  .aboutusArea .px-4 {
    padding: 0 !important;
  }

  .aboutusArea .row {
    flex-direction: column-reverse;
  }

  .aboutusArea .heading {
    text-align: center;
    padding-top: 20px;
  }

  .aboutusArea {
    text-align: center;
  }

  .extraCard {
    min-height: 170px;
    max-height: inherit;
  }

  /* .owl-carousel .offerCard img{ max-width: 120px; margin: 25px 0 0 22px;} */
  .whorighthands iframe {
    width: 100%;
    height: 260px;
  }

  .whorighthands p {
    padding: 0;
  }

  .ourProcess .heading h3 {
    padding: 0;
  }

  .easyBook .heading p {
    padding: 0;
  }

  .formSec {
    padding: 0;
  }

  .formSec .mb-5 {
    margin-bottom: 2rem !important;
  }

  .aboutProblem .btn {
    margin-bottom: 10px;
  }

  .aboutProblem .heading h3:nth-child(1) {
    margin: 20px 0 10px 0;
  }

  .aboutProblem .heading h3 {
    margin: 20px 0 20px 0;
  }

  .aboutProblem .heading {
    width: 90%;
    background: #fffefe96;
    padding: 10px;
    margin: 0;
  }

  .aboutProblem .heading p {
    padding-top: 0;
  }

  .aboutProblem {
    background-position: 100%;
  }

  .aboutPage .aboutusArea .row {
    flex-direction: column-reverse;
  }

  .aboutPage .problemsArea .row img {
    margin-bottom: 16px;
  }

  .happynesssArea {
    height: auto;
    padding: 20px 0;
  }

  .happyContent {
    min-height: inherit;
  }

  .experienceMatters {
    background-size: cover;
  }

  .experienceMattersContent {
    margin-left: 0;
    background: #ffffffcf;
    padding: 20px;
    border-radius: 0 100px 0 0;
  }

  .professionalsReview {
    justify-content: center;
    flex-wrap: wrap;
  }

  .professionalbox {
    height: auto;
    margin: 0 auto 16px;
    width: 100%;
  }

  .professionalsContent {
    text-align: center;
    padding: 16px 16px 0;
  }

  .professionalsContent p {
    margin-bottom: 0;
  }

  .memberShipBanner img {
    min-height: 310px;
  }

  .memberShipBanner .memberBnrContent {
    width: 35%;
    top: 10px;
  }

  .benefitContent {
    padding: 0 25px 20px;
  }

  .creditCardDiv {
    padding: 16px;
  }

  .loginButton {
    position: inherit;
    top: 0;
  }

  .extraCard .btn {
    right: 16px;
  }

  .errorPage {
    padding: 30px;
    display: block;
  }

  .pageNotFound {
    flex-direction: column;
  }

  .errorImage {
    margin: 0 auto 16px;
    display: table;
    padding: 0;
  }

  .errorImage img {
    width: 100%;
    max-width: 500px;
  }
}

@media (max-width: 680px) {
  #root .footerLinks ul li a {
    font-size: 13px;
  }
}

@media (max-width: 640px) {
  .servicedetail .modal-body {
    height: 200px;
    overflow-y: auto;
  }

  /* .footerserviceLinks ul{ display: block; column-count: 2;} */
  .ServiceStpes .item-container .divider {
    width: 200px;
  }

  .loginButton {
    left: 0;
    top: 0;
    margin: 0 auto;
    max-width: 90%;
  }

  .SliderbgImage {
    height: 200px;
  }
}

@media (max-width: 576px) {
  .stickyhead {
    position: static !important;
  }

  .navbar .dropdown-menu {
    min-width: 100%;
    overflow-y: inherit;
    height: auto;
  }

  .footerLinks {
    width: 50%;
    margin-right: 0;
  }

  .footerserviceLinks {
    width: 100%;
  }

  .translated-rtl .footerserviceLinks {
    width: 100%;
  }

  .iconbtn {
    margin: 0 5px 5px 0;
  }

  .iconbtn {
    justify-content: center;
  }

  .homeCleanBanner .bannerContent {
    padding: 20px;
  }

  .homeCleanBanner img {
    height: 220px;
  }

  .homeCleanBanner .bannerContent .btn {
    min-width: auto;
    padding: 10px 30px;
  }

  .appoitmentButton {
    padding: 20px 15px !important;
  }

  .tabBtn {
    min-width: auto;
    font-size: 16px;
    padding: 7px 10px;
  }

  .upcomingContent {
    padding: 0 15px;
  }

  .btn-cancel {
    min-width: 70px;
  }

  .verified {
    padding: 7px 10px;
    font-size: 13px;
  }

  .upcomingContent h3 span {
    font-size: 13px;
  }

  .appointmentService {
    padding: 15px;
  }

  .appointmentService h3 {
    flex-wrap: wrap;
  }

  .appointmentDetails h3 {
    margin-bottom: 15px;
  }

  .buttonSec h4 {
    margin-top: 20px;
  }

  .appointmentService h3 span {
    margin-top: 5px;
  }

  .buttonSec .btn {
    margin-bottom: 10px;
  }

  .buttonArea {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .professionalSec .serviceSlider {
    padding: 10px;
  }

  .complementsec {
    padding: 0 15px;
  }

  .stars {
    margin-bottom: 15px;
  }

  .stars label {
    font-size: 25px;
    margin: 0 10px;
  }

  .complementsec input {
    background-position: 5px;
    background-size: 50px;
    min-height: 60px;
    padding-left: 70px;
  }

  .tipButton button {
    width: 70px;
    margin-right: 10px;
    height: 70px;
    font-size: 14px;
  }

  .addAtip {
    padding: 15px;
  }

  .btn-fav span {
    font-size: 36px;
  }

  .btn-fav {
    font-size: 18px;
    min-width: 100%;
  }

  .checkboxItem {
    margin-bottom: 20px;
  }

  .checkboxItem .form-check input {
    margin-right: 10px;
  }

  .checkboxItem .form-check {
    align-items: center;
    justify-content: flex-start;
  }

  .checkboxItem .form-check label {
    font-size: 14px;
    text-align: left;
    line-height: 21px;
    width: 95%;
  }

  .bookingCancel {
    text-align: center;
  }

  .accountSettings h3 {
    padding-left: 20px;
  }

  .upcomingContent h3 {
    flex-wrap: wrap;
  }

  p {
    font-size: 14px;
  }

  .amount {
    flex-wrap: wrap;
  }

  .packageSlider .btn {
    min-width: 90%;
  }

  .helpItem p {
    flex-wrap: wrap;
  }

  .helpPage .verified {
    width: auto;
    margin: 0 auto 10px;
  }

  .helpPage .btn-cancel {
    margin: 0 auto 10px;
  }

  .helpPage .verified p {
    margin: 0 8px 0 10px;
  }

  .item-container .divider {
    width: 80px;
  }

  .ServiceStpes .item-container .divider {
    width: 95px;
  }

  .blogDeatilList .row {
    flex-direction: column-reverse;
  }

  .swal2-shown .swal2-popup {
    max-width: 100% !important;
  }

  .swal2-shown .swal2-actions {
    padding: 0 20px;
  }

  .swal2-shown .swal2-actions .swal2-styled {
    min-width: 100%;
  }

  .divMap > div > div {
    height: 400px !important;
  }

  .servicedetail .modal-body {
    height: auto;
    overflow-y: auto;
  }

  .professionalModal {
    height: 400px;
  }

  .Payment .checkboxItem .form-check label {
    width: auto;
  }

  .Payment .checkboxItem .form-check input {
    margin-right: 20px;
  }

  .stepService .buttonItem:not(.circleInput) label span {
    padding: 6px 10px;
  }

  .footerLinks ul li {
    width: 100%;
  }

  .socialIcon {
    justify-content: center;
    margin-top: 10px;
    width: 100%;
  }

  .socialIcon div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  #bookingSummary .card {
    height: 400px;
    overflow-y: auto;
  }

  /*Arbic*/
  .d-arabic {
    display: none;
  }

  .translated-rtl .navbar .ms-3 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .selectedLang {
    font-size: 14px;
  }

  .translated-rtl .d-arabic {
    display: flex !important;
  }

  .translated-rtl .englishcard {
    display: none !important;
  }

  .translated-rtl .homeCleanBanner .bannerContent {
    padding: 20px;
  }

  .translated-rtl .editAddress .checkboxItem .form-check label {
    width: 80%;
    text-align: right;
  }

  .translated-rtl .editAddress .checkboxItem p {
    padding-left: 0;
  }

  .translated-rtl .checkboxItem p {
    padding-right: 50px;
  }

  .translated-rtl .checkboxItem .form-check {
    padding-left: 0;
  }

  .extraCard .extraContent h3 br {
    display: none;
  }

  .translated-rtl .Payment .checkboxItem .form-check input {
    margin-right: 0;
  }

  .translated-rtl .checkboxItem .form-check label {
    text-align: right;
  }

  .memberShipBanner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .memberShipBanner .memberBnrContent {
    position: inherit;
    padding: 20px 0;
    text-align: center;
    width: 100%;
  }

  .memberShipBanner img {
    min-height: inherit;
  }

  .memberShippage h2 br {
    display: none;
  }

  .guarnteeBanner {
    background-size: cover;
    height: 360px;
  }

  .guarnteeBannerContent {
    padding: 30px;
    max-width: 95%;
  }

  .homeCards {
    padding-bottom: 24px;
  }
}

@media (max-width: 480px) {
  .item-container .divider {
    width: 40px;
  }

  .item-container .item.is-active {
    width: 80px;
  }

  .actionButton {
    padding: 20px 15px;
  }

  .editAddress .checkboxItem .form-check label {
    width: 90%;
  }

  .editAddress .checkboxItem p {
    padding-left: 40px;
    font-size: 14px;
  }

  .extraCard .extraContent h3 {
    font-size: 15px;
  }

  .extraCard .extraContent p {
    font-size: 12px;
    line-height: 20px;
  }

  .extraCard .extraContent {
    padding-right: 10px;
    margin-top: 10px;
  }

  .extraCard img {
    width: 120px;
  }

  .extraContent .btn-secondary {
    font-size: 13px;
  }

  .extraCard .extraContent h3 br {
    display: none;
  }

  .emergencyCard img {
    margin-top: 0;
    height: 130px;
    object-fit: cover;
  }

  .extraCard .extraContent h3 {
    font-size: 14px !important;
    line-height: 18px;
    margin-bottom: 5px;
  }

  .fullSlider.mt-5 {
    margin-top: 1rem !important;
  }

  .fullSlider .item .sliderContent h2 {
    font-size: calc(1.325rem + 0.9vw);
    margin-bottom: 8px;
  }

  .fullSlider .item .sliderContent h3 {
    font-size: 16px !important;
    line-height: 24px;
  }

  .fullSlider .item img {
    height: 320px;
  }

  .extraCard .extraContent p {
    font-size: 12px;
    line-height: 18px;
  }

  .extraCard .extraContent {
    padding-right: 10px;
    margin-top: 10px;
  }

  .extraCard img {
    width: 120px;
  }

  .extraContent .btn-secondary {
    font-size: 13px;
  }

  .extraCard .extraContent h3 br {
    display: none;
  }

  .emergencyCard img {
    margin-top: 0;
    height: 200px;
    object-fit: cover;
  }

  #bookingSummary .card {
    height: 480px;
    overflow-y: auto;
  }

  .aboutProblem .btn {
    margin: 0 auto 10px !important;
    display: table;
  }

  .owl-carousel .cardGuarantee img {
    width: 160px !important;
    height: auto;
  }

  /* .owl-carousel .offerCard img{ max-width: 120px;} */
  /* .offerCard .extraContent h3 {  margin-bottom: 50px;} */
  .homeCards .clubCard img {
    max-width: 140px;
  }

  .creditHeading {
    justify-content: center;
    flex-wrap: wrap;
  }

  .creditHeading h5 {
    margin-bottom: 16px;
  }
}

@media (min-width: 576px) {
  .homeCards .englishcard {
    display: none !important;
  }
}

@media (max-width: 575px) {
  .englishcard {
    display: block !important;
  }

  .creditPackage .serviceBanner .container {
    padding: 0 0 !important;
  }

  .item-container .item {
    width: 70px;
  }

  .packageSlider .checkboxItem {
    padding-left: 24px;
  }

  .buttonSec h4 {
    font-size: 14px;
  }

  .fullSlider .item .sliderContent {
    max-width: 360px;
  }

  .offerCard .extraContent h3 {
    word-break: auto-phrase;
  }
}

@media (max-width: 360px) {
  .ServiceStpes .item-container .divider {
    width: 60px;
  }

  .appoiintments .btn-confrim {
    min-width: auto;
    font-size: 12px;
    padding: 5px 7px;
  }

  .appoiintments .btn-cancel {
    min-width: auto;
    font-size: 12px;
    padding: 5px 7px;
  }

  .item-container .divider {
    width: 30px;
  }

  .item-container .item.is-active {
    width: 80px;
  }

  .actionButton {
    padding: 20px 10px;
  }

  .buttonItem label span {
    font-size: 12px;
    min-width: auto;
    padding: 6px 10px;
  }

  .buttonItem input:checked + span {
    font-size: 12px;
    padding: 6px 10px !important;
  }

  .stepService .circleInput label span {
    width: 34px;
    min-width: 34px;
    height: 34px;
  }

  .homeCards .emergencyCard img {
    width: 170px !important;
  }

  .bg-primary.extraCard.emergencyCard {
    min-height: 180px;
  }

  /* .editAddress .checkboxItem{    flex-direction: column; } */
}

@media (max-width: 1400px) {
  .divMap div:nth-child(1) div:first-child {
    max-height: 550px !important;
  }

  .modal-body .successPage {
    height: 500px;
    overflow-y: auto;
  }

  #bookingSuccess .modal-body .successPage {
    height: 500px;
    overflow-y: visible;
  }
}

@media (max-width: 991px) {
  .modal-open .modal.show {
    align-items: flex-start;
  }
}

@media (min-width: 1025px) and (max-width: 1300px) {
  #serviceinfo .modal-body {
    height: 480px;
  }

  #cleaningMaterial .modal-body {
    height: 480px;
  }

  .divMap div:nth-child(1) div:first-child {
    max-height: 480px !important;
  }

  #addAddress .modal-body {
    height: 440px;
  }

  .modal-body .successPage {
    height: 440px;
    overflow-y: auto;
  }

  #bookingSuccess .modal-body .successPage {
    height: 440px;
    overflow-y: auto;
  }

  #addAddress .divMap div:nth-child(1) div:first-child {
    max-height: 440px !important;
  }
}

.blogDetail .socailIcon span.fa-link {
  border-radius: 0 !important;
  font-size: 16px;
  height: 36px !important;
  line-height: 36px !important;
  width: 36px !important;
}

/* .translated-rtl .homeCards .owl-carousel .owl-item{ margin-right: 0 !important; margin-left: 8px !important;} */
.professionalModal .transtionList > h3 span {
  min-width: 55px;
  text-align: right;
}

.translated-rtl .professionalModal .transtionList > h3 span {
  text-align: left;
}

.step-wizard .step-title {
  width: 250px;
  text-align: center;
}

.serviceinfo .modal-header {
  padding: 20px 20px 0px 20px;
  border-bottom: 0px solid #dee2e6;
  position: absolute;
  right: 5px;
  z-index: 9999;
}

.cleaningMaterial .modal-header {
  padding: 20px 20px 0px 20px;
  border-bottom: 0px solid #dee2e6;
  position: absolute;
  right: 5px;
  z-index: 9999;
}

#ProfileSetup .phonecodeselect.col-lg-7 .form-control {
  padding-left: 16px;
}

.changeEye {
  position: relative;
}

.changeEye i {
  position: absolute;
  right: 20px;
  top: 48px;
  font-size: 25px;
  color: #432375;
  cursor: pointer;
}

.translated-rtl .changeEye i {
  left: 20px;
  top: 48px;
  right: auto;
}

.changeEyeSignUp {
  position: relative;
}

.changeEyeSignUp i {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 25px;
  color: #432375;
  cursor: pointer;
}

.translated-rtl .changeEyeSignUp i {
  left: 20px;
  top: 10px;
  right: auto;
}

.disabled-link {
  pointer-events: none;
}

.bannerSearch {
  position: relative;
}

.bannerSearchspanLeft {
  position: absolute;
  top: 50%;
  color: #b2b2b2;
  font-size: 24px;
  left: 20px;
  transform: translate(0px, -50%);
}

.bannerSearchspanRight {
  position: absolute;
  top: 50%;
  color: #b2b2b2;
  font-size: 24px;
  right: 2px;
  transform: translate(0px, -50%);
}

.bannerSearch .form-control {
  padding-left: 55px !important;
}

.autosign {
  border: 0;
  background: none;
  margin-top: 5px;
}

.btn-right {
  text-align: right;
  width: 100%;
}

.offerCard a.btn.btn-secondary {
  margin-top: 38px;
}

.iconbtn {
  align-items: center;
}

.iconbtn svg {
  fill: #b793ee;
}

.iconbtn:hover i {
  color: #fff;
}

.iconbtn:hover svg {
  fill: #fff;
}

.mt-6 {
  margin-top: 1rem !important;
  margin-bottom: 2rem !important;
}
.payExtraLabel {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.professionalLabel {
  display: flex;
  font-size: 16px;
  gap: 40px;
  justify-content: flex-end;
}
.proLabel {
  background-color: #e8e8e8;
  background-image: none;
  border: 0 solid #0000;
  border-radius: 4px;
  color: #0009 !important;
  display: inline-block;
  font-weight: 700 !important;
  line-height: 1;
  margin: 0 0.14285714em;
  padding: 0.5833em 0.833em;
  text-transform: none;
  transition: background 0.1sease;
  vertical-align: initial;
  font-size: 14px;
}
.labelDetail {
  display: inline-block;
  font-weight: 700;
  margin-left: 1em;
  opacity: 0.8;
  vertical-align: top;
}

.alert-container {
  display: flex;
  align-items: center;
  color: #ff0007;
  font-weight: bold;
  font-size: 16px;
  margin-left: 0px;
  padding-left: 0px;
}

.alert-icon {
  margin-right: 8px;
}

.cardList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px;

  .services-card {
    height: fit-content;
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}

/* src/components/Card.css */
.services-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* 3 items per row */
  gap: 10px;
  margin-top: 10px;
  width: 100%;
}

.remaining-services-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* 3 items per row */
  gap: 10px;
  margin-top: 10px;
  width: 100%;
  padding-bottom: 2%;
}

@media (max-width: "1024px") {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
    /* 2 items per row */
  }

  .services-card {
    width: 100%;
    min-width: 187.5px;
    min-height: 87.5px;
  }
}

.services-card {
  height: fit-content;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
  width: 100%;
  min-width: 200px;
  min-height: 94px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(30% - 20px);
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.remaining-services-container {
  display: grid;
  /* 3 items per row */
  gap: 5px;
  margin-top: 10px;
  width: 100%;
}

.plain-text-service {
  font-size: 16px;
  color: #555;
  padding: 5px 10px 5px 0px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: bold;
}

.card-img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.card-body {
  padding: 15px;
}

.card-title {
  font-size: 16px;
  font-weight: bold;
  margin-top: 5% !important;
}

.card-text {
  font-size: 14px;
  color: #555;
}

.background-image-service-booking {
  max-width: 100%;
  margin-bottom: 30px;
  height: 300px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* background-image: url("/public/images/b.jpg"); */
}

@media (max-width: 1024px) {
  .background-image-service-booking {
    max-width: 100%;
    margin-bottom: 30px;
    height: 300px;
    border-radius: none;
    box-shadow: none;
    background-image: none !important;
  }
}

.form-element-border {
  border: 1px solid grey;
  width: 400px;
  margin-bottom: 30px;
}

.booking-form {
  width: 60%;
  max-width: 960px;
  height: 350px;
  align-items: center;
  text-align: center;
  margin-top: 30%;
  margin-left: 20.2%;
  margin-right: 17.5%;
  margin-bottom: 15%;
}

@media (min-width: 1600px) {
  .booking-form {
    width: 40%;
    max-width: none;
    height: fit-content;
    align-items: center;
    text-align: center;
    margin-top: 25%;
    margin-left: 30%;
    margin-right: 30%;
    margin-bottom: 20%;
  }
}

@media (max-width: 1300px) {
  .booking-form {
    width: 95%;
    max-width: 95%;
    height: fit-content;
    box-shadow: none;
    background-color: none;
    border: none;
    margin-left: 5%;
    margin-right: 5%;
  }
}

.background-white-shadow {
  border: 2px solid ghostwhite;
  box-shadow: 0 0 20px 10px rgb(0 0 0 / 12%);
  background-color: white;
  margin-top: 8%;
}

.form-style-service {
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.pro-selection {
  display: flex;
  width: 100%;
  /* flex: ; */
  justify-content: space-between;
}

.frequency-dropdown {
  width: 38%;
  height: 45px;
  border-radius: 2px;
}

@media (max-width: 1300px) {
  .frequency-dropdown {
    width: 100%;
    margin-right: 10%;
  }
}

.booking-continue-button {
  margin-left: 30px;
  width: 50%;
  height: 50px;
}

.select-dropdown-service {
  min-width: 100px;
  height: 45px;
  border-radius: 2px;
}

@media (max-width: 1024px) {
  .select-dropdown-service {
    width: 50% !important;
    min-width: 0% !important;
  }
}

.how-works-header {
  margin-top: 4%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  hr {
    height: 1.5px;
    width: 300px;
    color: rgb(167, 5, 255);
  }
}

.how-works-items {
  margin-top: 3%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-left: 15%;
  margin-right: 15%;

  .how-works-subitems {
    p {
      width: 200px;
      text-align: center;
    }
  }
}

@media (max-width: 1024px) {
  .how-works-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0%;
    margin-right: 0%;
  }

  .how-works-subitems {
    margin-top: 5%;
  }
}

.how-book {
  margin-top: 4%;
  text-align: center;
  /* background-color: grey; */

  .how-book-header {
    margin-left: 10%;
    margin-right: 10%;

    h2 {
      font-size: 40px;
      font-weight: normal;
    }
  }

  .how-book-items {
    display: flex;
    justify-content: space-around;

    .items-left {
      margin-bottom: 10px;
    }
  }
}

.how-book-sub-items {
  p {
    text-align: left;
    width: 250px;
    margin-left: 30px;
  }

  .sub-items-header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 4%;

    .sub-items-img {
      width: 30px;
      height: 30px;
      margin-left: -20px;
    }

    h3 {
      font-weight: normal;
      margin-left: 20px;
    }
  }
}

@media (max-width: 1024px) {
  .why-book-service {
    width: 100% !important;
  }

  .why-book-service-main {
    width: 50% !important;
  }

  ..why-book-service-main-new {
    width: 50% !important;
  }
}

.why-book-service-package {
  width: 90%;
  display: flex;
}

.why-book-service {
  box-shadow: 0 4px 8px rgba(57, 117, 12, 0.1);
  margin-bottom: 3%;

  .why-book-service-main-new {
    margin-left: 15%;
    margin-right: 4%;
    margin-top: 5%;
    width: 65%;
    h1 {
      margin: 0% 5% 2% 5%;
      font-size: 2em;
    }

    p {
      margin: 0% 5% 2% 5%;
      font-weight: 400;
      font-size: 1.25em;
    }

    .why-book-list {
      font-size: 1.25em;
      margin-left: 30px;
      margin-bottom: 2%;
    }

    .tick-list {
      list-style-type: none;
      /* Remove default bullet points */
      padding-left: 3%;
      /* Add padding to align text properly */
    }

    .tick-list li {
      position: relative;
      margin: 10px 0;
      padding-left: 20px;
      /* Offset text from the checkmark */
    }

    .tick-list li::before {
      content: "✔";
      /* Unicode character for checkmark */
      color: #c79ddd;
      /* Set checkmark color */
      font-size: 18px;
      font-family: "Hanken Grotesk", serif !important;
      position: absolute;
      left: 0;
      right: 20;
      top: 0;
    }
  }

  .why-book-service-main {
    margin-left: 5%;
    margin-right: 4%;
    margin-top: 5%;
    width: 65%;
    h1 {
      margin: 0% 5% 2% 5%;
      font-size: 2em;
    }

    p {
      margin: 0% 5% 2% 5%;
      font-weight: 400;
      font-size: 1.25em;
    }

    .why-book-list {
      font-size: 1.25em;
      margin-left: 30px;
      margin-bottom: 2%;
    }

    .tick-list {
      list-style-type: none;
      /* Remove default bullet points */
      padding-left: 3%;
      /* Add padding to align text properly */
    }

    .tick-list li {
      position: relative;
      margin: 10px 0;
      padding-left: 20px;
      /* Offset text from the checkmark */
    }

    .tick-list li::before {
      content: "✔";
      /* Unicode character for checkmark */
      color: #c79ddd;
      /* Set checkmark color */
      font-size: 18px;
      font-family: "Hanken Grotesk", serif !important;
      position: absolute;
      left: 0;
      right: 20;
      top: 0;
    }
  }
}

.background-grey {
  background-color: #f8f8f8;
  margin-bottom: 5px;
}

.whats-included {
  margin-bottom: 3%;

  h2 {
    margin-top: 14%;
    text-align: center;
    font-size: 2em;
    line-height: 1.25em;
  }

  p {
    margin-left: 25%;
    width: 700px;
    text-align: center;
    font-size: 1.25em;
    line-height: 1.25em;
    font-weight: 400;
  }

  .whats-included-header {
    margin-top: 30%;
  }

  .whats-included-items {
    display: flex;
    justify-content: space-around;
    height: 480px;

    .whats-included-items-background {
      background-image: url("/public/images/banner-image1.jpg");
      background-position: left top;
      width: 50%;
      position: relative;
      z-index: 1;
    }

    .whats-include-items-text {
      background-color: white;
      width: 45%;
      margin: auto;
      padding-top: 4%;
      padding-left: 4%;
      position: relative;
      z-index: 2;

      .items-list {
        margin-left: 5%;
        margin-right: 5%;
      }
    }
  }
}

.why-righthands-best {
  margin-left: 15%;
  margin-right: 15%;
  max-width: 68% !important;
  font-size: 2em;

  h4 {
    font-size: 0.7em;
    margin-bottom: 2%;
    margin-top: 0%;
  }

  p {
    margin-bottom: 3%;
    margin-top: 0%;
  }

  button {
    margin-bottom: 2%;
    margin-top: 0%;
  }
}

.locations-main {
  margin-top: 2% !important;
  border: none;
}

.locations {
  margin-bottom: 10px;
  height: max-content;

  .locations-header {
    text-align: center;
  }

  h2 {
    margin-top: 5%;
    padding-top: 2%;
  }

  .locations-item {
    background-image: url("/public/images/location-background.svg");
    background-repeat: no-repeat;
    background-position: right;
    padding-top: 10px;
    padding-left: 5px;
    margin-left: 20%;
    margin-top: 3%;

    .items-list {
      margin-left: 5%;
      margin-right: 5%;
    }
  }
}

@media (max-width: 1024px) {
  .locations-item {
    width: 100% !important;
    background-size: 70% !important;
    margin-left: 0% !important;
  }
}

.locations-item-weight {
  padding-left: 5px;
  padding-top: 10px;
  width: 60%;
}

.display-flex {
  display: flex;
  justify-content: flex-start;
}

.cardList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px;
  height: fit-content;
}

/* src/components/Card.css */
.card-services {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
  width: 100%;
  max-width: 300px;
  margin: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 350px;
  flex: 1 1 calc(30% - 20px);
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

/* .card:hover {
  transform: scale(1.05);
} */

.card-img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.card-body {
  padding: 15px;
}

.card-text {
  font-size: 14px;
  color: #555;
}

.search-container {
  border: none !important;
  outline: none !important;
  margin-right: "10px";
  background-color: none;
}

.search-container:focus {
  border: none;
  outline: none;
}

.popular-search-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 3 equal columns */
  grid-template-rows: repeat(5, auto);
  /* 5 rows */
  gap: 10px;
  /* Space between items */
  max-width: 100%;
  /* Set a max width for the grid */
  margin: auto;
  /* Center the grid */
}

.display-flex-space-between {
  display: flex;
  justify-content: space-between;
}

.appointment-summary-details {
  width: 60%;

  h2 {
    text-align: center;
    margin-top: 4%;
  }

  hr {
    margin-top: 4%;
    margin-bottom: 4%;
  }

  .btn-space {
    margin-right: 2%;
    /* border-radius: 3px; */
    height: 50px;
  }

  .promo-input {
    width: 30%;
  }

  .formSelect-style {
    width: 97%;
    height: 40px;
    border-radius: 10px;
  }

  .formSelect-input-style {
    height: 35px;
    width: 70%;
    border-radius: 4px;
  }
}

.booking-summary-details {
  width: 30%;
  height: fit-content;
}

.left-space {
  margin-left: 4%;
  font-weight: bolder;
}

.right-space {
  margin-right: 4%;
  font-weight: bold;
}

.rightHands-background-color {
  background-color: rgb(229, 187, 251);
  border: none;
  border-radius: 2%;
}

.faqs {
  margin-left: 15%;
  margin-right: 25%;

  .faqs-heading {
    text-align: left;
    margin-bottom: 5%;
  }

  .ans {
    transition: transform 0.5s ease-in-out;
    margin-bottom: 3%;
  }
}

.initial-height {
  margin-bottom: 5%;
}

.payment-cards {
  display: flex;
  align-items: center;
  max-width: 40%;
  justify-content: flex-start;
}

.radio-container {
  display: flex;
  align-items: center;
}

.radio-container h4 {
  margin: 0;
  /* Removes any default margin around the h4 */
}

.address-details {
  margin-left: 10%;
}

.btn-appointmentSummary {
  background-color: #fcd500;
  border-color: #fcd500;
  border-radius: 20px;
}

.show-more-address {
  background: none;
  border: none;
  color: #0000ee;

  :hover {
    cursor: pointer;
    color: #551a8b;
  }
}

.ques-icon {
  font-size: 150%;
  cursor: pointer;
}

.ques-icon:hover {
  color: lightblue;
}

.ques-click {
  cursor: pointer;
}

.responsive-image {
  min-height: 2rem;
  height: auto;
  /* Maintain aspect ratio */
  object-fit: contain;
  /* Prevent cropping */
}

.dropdown-menu-services {
  display: none;
  border: 1px solid black;
  background-color: white;
  box-shadow: #0000ee;
}

.dropdown-menu-services:hover {
  display: inline;
}

.all-services:hover {
  display: block;
}

.home-cleaning:hover {
  border: 1px solid #8cc43c;

  .service-Link {
    color: #8cc43c !important;
  }
}

.all-services-block:hover {
  border: 1px solid #44d4bc;

  .service-Link {
    color: #44d4bc !important;
  }
}

@media (max-width: 1240px) {
  .locationSearch-nav {
    display: none;
  }
}

.no-calendar {
  border: 1px solid black;
}

.home-services {
  grid-template-columns: repeat(5, 1fr);
}

@media (max-width: 1024px) {
  .home-services {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .home-services {
    grid-template-columns: repeat(2, 1fr);
  }
}

.home-all-services {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 1300px) {
  .service-booking {
    margin-bottom: 5%;
    align-items: center;
    width: 100%;
    margin-right: 10%;
  }

  .small-service-booking {
    flex-direction: column-reverse;
    width: 100%;
  }

  .pro-selection {
    flex-wrap: wrap !important;
    margin-bottom: 5%;
    align-items: center;
  }

  .location-pro {
    margin-top: -40px;
    width: 100% !important;
  }

  .show-wide {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .hide-wide {
    display: none !important;
  }
}

.location-pro {
  margin-top: -40px;
  width: 70%;
}

@media (max-width: 1024px) {
  .booking-continue-button {
    width: 100% !important;
    font-size: 10px !important;
    margin-left: 0% !important;
  }

  .how-works-small {
    margin-top: 90% !important;
  }

  .how-works-small-package {
    margin-top: 10% !important;
  }

  .how-works-header h1 {
    font-size: medium;
    text-align: center;
    width: -webkit-fill-available;
  }
}

@media (max-width: 450px) {
  .booking-continue-button {
    width: 100% !important;
    font-size: 10px !important;
    margin-left: 0% !important;
  }

  .how-works-small {
    margin-top: 100% !important;
  }

  .how-works-small-package {
    margin-top: 10% !important;
  }

  .how-works-header h1 {
    font-size: medium;
    text-align: center;
    width: -webkit-fill-available;
  }
}

@media (max-width: 1024px) {
  .hide-small-screen {
    display: none;
  }

  .show-small-screen {
    display: initial;
  }
}

.show-small-screen {
  display: none;
}

.package-service-right {
  margin-right: 10%;
  width: 98% !important;
  text-align: center;
  align-items: center;
  margin-top: 23% !important;
  padding: 3%;
}

.appointment-summary-details {
  flex: 1; /* Takes up remaining space */
  overflow-y: auto; /* Enables vertical scrolling */
  padding: 1rem;
}

.booking-summary-details {
  background-color: #fff;
  margin-left: 1rem;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: auto; /* Prevents content cut-off */
}

@media (max-width: 1024px) {
  .package-service-right {
    width: 85% !important;
  }
}

/* .map-search {
  position: relative;
          border: 1px solid rgb(224, 224, 224);
          background-color: 'white';
          display: 'flex';
          padding: '7px';
          margin-left: '-10px';
          align-items: 'center';
          border-radius: '5px';
} */

@media (max-width: 1300px) {
  .map-search {
    margin-right: 8%;
  }
}

.slick-slider {
  width: 100%;
  overflow: hidden; /* Prevents extra space */
}

.slick-list {
  overflow: hidden; /* Stops cut-off issues */
  padding-right: 10px; /* Adjust this if needed */
}

.slick-track {
  display: flex !important;
  flex-wrap: nowrap !important;
}

.slick-slide {
  display: flex !important;
  justify-content: center;
  padding: 0 10px; /* Space between slides */
  flex: 0 0 auto; /* Prevents shrinking */
}

.slide-item {
  width: 250px; /* Ensure each item has a fixed width */
  background: #ddd;
  text-align: center;
  padding: 20px;
}

.service-booking-marginBottom {
  margin-bottom: 17%;
}

@media (max-width: 1300px) {
  .service-booking-marginBottom {
    margin-bottom: 56%;
  }
}

.quality-service {
  margin-top: 0%;
}
.quality-service-main {
  margin-left: 12%;
  margin-right: 12%;
}

.quality-service-heading {
  text-align: center;
}

.quality-service-description {
  text-align: center;
  width: 50%;
  margin-left: 25%;
}

.quality-service-points-list {
  margin-left: 10%;
  margin-right: 10%;
}

.quality-service-points-list-items {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}

.quality-service-points {
  width: 40%;
}

.benefits {
  margin-bottom: 5%;
  padding-bottom: 2%;
}

.benefits-heading {
  text-align: center;
}

.benefits-item {
  margin-top: 3.5%;
  margin-bottom: 1.5%;
}

.benefits-image {
  width: 50%;
  min-height: 80%;
}

@media (max-width: 1024px) {
  .benefits-image {
    display: none;
  }

  .benefits-text {
    width: 100% !important;
  }
}

.benefits-text {
  width: 50%;
  margin-top: 2.5% !important;
  margin-left: 5%;
  margin-right: 5%;
  height: fit-content;
  padding: 1% 1% 1% 1%;
}

.serviceCategorySlider {
  width: 100%;
  padding: 0px;
}

.incr-decr-btn {
  color: grey;
  font-size: larger;
  width: 30% !important;
}
.incr-decr-btn:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.min-order-style {
  font-size: small;
}

.package-service-info-modal {
  padding: 1% 5%;

  .left-header {
    font-weight: bold;
  }

  .right-item {
    font-weight: normal;
  }
}

.service-package {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 5%;
  margin-bottom: 5%;
}

@media (max-width: 1024px) {
  .service-package {
    display: block;
  }

  .service-package-items {
    width: 100% !important;
    margin-right: 0% !important;
    display: block !important;
  }
}

@media (max-width: 648px) {
  .service-package {
    display: block;
  }

  .service-package-items {
    width: 100% !important;
    margin-right: 0% !important;
    display: block !important;
  }
}

.service-package-items {
  width: 30%;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 2%;
}

@media (max-width: 648px) {
  .why-book-service-package {
    display: block;
    margin-top: 25% !important;
  }

  .why-book-service-main {
    width: 100% !important;
  }

  .why-book-service-main-new {
    width: 100% !important;
  }

  .why-book-service-main-new {
    margin-left: 5% !important;
  }

  .service-package .service-package-items {
    width: 100% !important;
  }

  .service-package-items {
    margin-left: 25%;
  }

  .package-service-right {
    margin-left: 10%;
  }

  .quality-service-points {
    width: 100% !important;
  }

  .quality-service-points-list-items {
    display: block;
    text-align: center;

    img {
      display: none;
    }
  }
}

.first-pill {
  margin-left: 5%;
}

.last-pill {
  margin-right: 5%;
}
